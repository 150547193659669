import { Flex, useMediaQuery, Text, Box, Alert, AlertIcon } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { RareAnimalsContext } from '../../../contexts/RareAnimalsContext';
import { SanctuaryConditionsContext } from '../../../contexts/SanctuaryConditionsContext';
import NextUpTable from './NextUpTable';

const RareAnimals = () => {
    const { forecastRange } = useContext(RareAnimalsContext);
    const [isSmallDevice] = useMediaQuery('(max-width: 768px)');
    useContext(SanctuaryConditionsContext); // Subscribe for re-renders

    return (
        <Box overflowY="visible">
            <Flex grow={1} direction="column"> 
                <Alert status='warning' fontSize={'small'} mb={2}>
                    <AlertIcon />
                    <Box>
                        <p>New animals available as of patch 6.3.</p>
                    </Box>
                </Alert>
                <Text fontSize={isSmallDevice ? 'sm' : 'md'}>Showing the next <Text as="b">{forecastRange}</Text> appearance{forecastRange !== 1 && 's'} per animal based on when they become available.</Text>
                <Box pt={4}>
                    <NextUpTable />
                </Box>
            </Flex>
        </Box>
    );
};

export default RareAnimals;