import React, { CSSProperties, useEffect, useRef } from 'react';

interface IAdsenseUnitProps {
    slot: string;
    isHeader?: boolean;
}

const AdsenseUnit = ({ slot, isHeader = false }: IAdsenseUnitProps) => {
    const elementRef = useRef<HTMLModElement>(null);
    useEffect(() => {
        if (window.location.host === 'www.ffxiv-sanctuary.com') {
            try {
                // @ts-ignore
                (window.adsbygoogle = window.adsbygoogle || []).push({})
            } catch(e) {
                console.error(e);
            }
        }
    }, [elementRef])

    const style = isHeader ? { display: 'inline-block', width: '728px', height: '90px', maxHeight: '90px' } as CSSProperties : { flex: 1 } as CSSProperties;

    return (
        <ins
            ref={elementRef}
            className="adsbygoogle"
            style={style}
            data-ad-client="ca-pub-9138012853034019"
            data-ad-slot={slot}
            data-ad-format={isHeader ? 'horizontal' : 'auto'}
            data-full-width-responsive="true" />
    );
};

export default AdsenseUnit;