import React from 'react';
import { Link, useColorModeValue } from '@chakra-ui/react';
import { Link as RouterLink } from "react-router-dom";
import { INavItem } from '../../router';

interface INavLinkProps {
    route: INavItem;
    isHamburger?: boolean;
}

const NavLink = ({ route: { href, label }, isHamburger }: INavLinkProps) => (
    <Link
        px={isHamburger ? 4 : 2}
        py={2}
        rounded={isHamburger ? 'none' : 'md'}
        fontSize={isHamburger ? 'md' : 'sm'}
        _hover={{
            textDecoration: 'none',
            bg: useColorModeValue('gray.100', 'gray.700'),
        }}
        as={RouterLink}
        to={href}
    >
        {label}
    </Link>
);

export default NavLink;
