import { Flex, useMediaQuery, Text, useColorModeValue, Box, Heading, Alert, AlertIcon } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import SanctuaryMap, { IMarker, MarkerColor } from '../../components/SanctuaryMap/SanctuaryMap';
import nodes, { ICoordinate, INode, INodeDrop } from '../../data/nodes';
import Legend from './Legend';
import MaterialsForm from './MaterialsForm';

export interface IMapData {
    node: INode;
    materials?: string[];
    color?: MarkerColor;
}

const colors = Object.values(MarkerColor);
const getColorByIndex = (index: number) => colors[index % colors.length];

const Gathering = () => {
    const mapContainerRef = useRef<HTMLDivElement>(null);
    const [mapSize, setMapSize] = useState(0);
    const [isSmallDevice] = useMediaQuery('(max-width: 768px)');
    const [filterValues, setFilterValues] = useState<string[]>([]);
    const [mapData, setMapData] = useState<IMapData[]>([]);

    useEffect(() => {
        setMapSize(mapContainerRef.current?.clientWidth || 0);
    }, [mapContainerRef.current?.clientWidth]);

    useEffect(() => {
        const newMapData: IMapData[] = [];
        
        filterValues.forEach((value: string) => {
            // Find any nodes that include this drop
            const filtered = nodes.filter((n: INode) => {
                return n.drops.find((d: INodeDrop) => d.name === value);
            });

            // Add any filtered nodes that don't already exist in the array
            filtered.forEach((f: INode) => {
                if (!newMapData.find((mD: IMapData) => mD.node.name === f.name)) {
                    newMapData.push({ node: f, materials: [] });
                }
            });

            // Apply the material to the materials array of each relevant node
            const withNode = newMapData.filter((mD: IMapData) => mD.node.drops.find((d: INodeDrop) => d.name === value));
            withNode.forEach((w: IMapData) => w.materials?.push(value));
        });

        // Finally, loop back through and apply colors
        for (let i = 0; i < newMapData.length; i ++) {
            newMapData[i].color = getColorByIndex(i);
        }

        setMapData(newMapData);
    }, [filterValues]);

    const mapDataToMarkers = () => {
        const markers: IMarker[] = [];

        mapData.forEach((mD: IMapData) => {
            const newMarkers: IMarker[] = mD.node.locations.map((l: ICoordinate) => ({ coordinate: { x: l.x, y: l.y }, size: 0.5, color: mD.color }));
            markers.push(...newMarkers);
        })

        return markers;
    }

    mapDataToMarkers();
    
    return (
        <Flex direction="column" grow={1} width="10">
            <PageHeader title="Gathering" />
            <Text mb={2}>Select the materials or seeds you're looking for to visualize them on the map.</Text>
            <Alert status='warning' fontSize={'small'} mb={2}>
                <AlertIcon />
                <Box>
                    <p>New gathering nodes and items have been added as of patch 6.3.</p>
                    <p>Note that Multicolored Isleblooms require flight to reach.</p>
                </Box>
            </Alert>
            <Flex direction={isSmallDevice ? 'column-reverse' : 'row'}>
                <Flex grow={1} ref={mapContainerRef}>
                    {!!mapSize &&
                        <Box width={mapSize} height={mapSize} borderRadius="md" overflow="hidden">
                            <SanctuaryMap markers={mapDataToMarkers()} />
                        </Box>
                    }
                </Flex>
                <Box width={isSmallDevice ? '100%' : '35%'} ml={isSmallDevice ? 0 : 4}>
                    <Flex grow={1} bg={useColorModeValue('gray.100', 'gray.900')} borderRadius="md" p={4} direction="column" mb={2}>
                        <MaterialsForm filterValues={filterValues} setFilterValues={setFilterValues} />

                        <Heading as="h3" size="md" mb={2}>Legend</Heading>
                        {mapData.length ? <Legend mapData={mapData} /> : <Text as="i">No materials selected</Text>}
                    </Flex>
                </Box>
            </Flex>
        </Flex>
    );
};

export default Gathering;
