import React from 'react';
import { HStack, Link, Td, Tr, useColorModeValue, Image } from '@chakra-ui/react';
import format from 'date-fns/format';
import { IAnimalForecastItem } from './NextUpTable';
import { IAnimal } from '../../../data/animals';

interface IMobileNextUpRowProps {
    forecastItem: IAnimalForecastItem;
    onOpenModal: (animal: IAnimal) => void;
}

const MobileNextUpRow = ({ forecastItem: { animal, forecast }, onOpenModal }: IMobileNextUpRowProps) => {
    const { name, icon } = animal;
    // @ts-ignore
    const { startsAt } = forecast;
    const highlightColor = useColorModeValue('green.100', 'green.600');

    const getIsCurrentlyActive = () => {
        return new Date() >= startsAt;
    }

    const getFormattedStartTime = () => {
        const startTime = startsAt;
        return startTime.getDay() === new Date().getDay() ? format(startTime, 'HH:mm:ss') : format(startTime, 'MMM d, HH:mm:ss');
    }

    const renderStartTimeLT = () => {
        return <Td>{getIsCurrentlyActive() ? 'Now' : getFormattedStartTime()}</Td>;
    };

    return (
        <Tr backgroundColor={getIsCurrentlyActive() ? highlightColor : 'none'} fontSize='sm'>
            <Td>
                <Link textDecoration="underline" onClick={() => onOpenModal(animal)}>
                    <HStack>{icon && <Image src={icon} boxSize="8" borderRadius="full"/>}<span>{name}</span></HStack>
                </Link>
            </Td>
            {renderStartTimeLT()}
        </Tr>
    );
};

export default MobileNextUpRow;
