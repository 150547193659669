import React, { createContext, useEffect, useState } from 'react';
import animals, { IAnimal, IAnimalDrop } from '../data/animals';

export interface ICollectionItem {
    animalName: string;
    count: number;
}

interface ICollection {
    collection: ICollectionItem[];
    addToCollection: (animalName: string, amount?: number) => void;
    removeFromCollection: (animalName: string) => void;
    getCollectionCount: () => number;
    getDropsAssessment: () => IDropsAssessment;
}

interface ICollectionProviderProps {
    children: JSX.Element | JSX.Element[];
}

export interface IDropItem {
    material: string;
    count: number;
}

interface IDropsAssessment {
    common: IDropItem[];
    rare: IDropItem[];
}

export const CollectionContext = createContext<ICollection>({
    collection: [],
    addToCollection: () => {},
    removeFromCollection: () => {},
    getCollectionCount: () => 0,
    getDropsAssessment: () => ({ common: [], rare: [] }),
});

const CollectionProvider = ({ children }: ICollectionProviderProps) => {
    const [collection, setCollection] = useState<ICollectionItem[]>([]);

    useEffect(() => {
        const stored = localStorage.getItem('collection');
        if (!!stored) {
            setCollection(JSON.parse(stored));
        }
    }, [])

    const addToCollection = (animalName: string, amount: number = 1) => {
        // Is there already an instance of this animal in the collection?
        const collectionItem = collection.find((c: ICollectionItem) => c.animalName === animalName);

        if (collectionItem) {
            const { count } = collectionItem;
            let newCollection = collection.filter((c: ICollectionItem) => c.animalName !== animalName);
            newCollection = [...newCollection, { animalName, count: count + amount }]

            setCollection(newCollection);
            localStorage.setItem('collection', JSON.stringify(newCollection));
        } else {
            setCollection((prev: ICollectionItem[]) => {
                const newCollection = [...prev, { animalName, count: amount }];

                localStorage.setItem('collection', JSON.stringify(newCollection));
                return newCollection;
            })
        }
    }

    const removeFromCollection = (animalName: string) => {
        const collectionItem = collection.find((c: ICollectionItem) => c.animalName === animalName);

        if (collectionItem) {
            collectionItem.count = collectionItem.count - 1;
            let newCollection = collection.filter((c: ICollectionItem) => c.animalName !== animalName);

            if (collectionItem.count) {
                newCollection = [...newCollection, collectionItem];
            }

            localStorage.setItem('collection', JSON.stringify(newCollection));
            setCollection(newCollection);
        }
    }

     const getCollectionCount = () => {
        return collection.reduce((prev: number, item: ICollectionItem) => prev + item.count, 0);
     }

     const getDropsAssessment = () => {
        return collection.reduce((prev: IDropsAssessment, item: ICollectionItem) => {
            const { common, rare } = prev;
            const animal = animals.find((f: IAnimal) => f.name === item.animalName);

            const newAssessment = { ...prev };

            if (animal) {
                const { drops } = animal;
                drops?.forEach((d: IAnimalDrop) => {
                    if (d.isRare) {
                        const last = rare.find((i: IDropItem) => i.material === d.name);
                        if (last) {
                            last.count += item.count;
                        } else {
                            prev.rare.push({ material: d.name, count : item.count });
                        }
                    } else {
                        const last = common.find((i: IDropItem) => i.material === d.name);
                        if (last) {
                            last.count += item.count;
                        } else {
                            prev.common.push({ material: d.name, count : item.count });
                        }
                    }
                });
            }
            
            return newAssessment;
        }, { common: [], rare: []});
     }

    return (
        <CollectionContext.Provider value={{ collection, addToCollection, removeFromCollection, getCollectionCount, getDropsAssessment }}>
            {children}
        </CollectionContext.Provider>
    );
};

export default CollectionProvider;