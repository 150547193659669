import { FormControl, HStack, Select, Box, IconButton } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import { TrackingContext } from '../../contexts/TrackingContext';
import animals, { IAnimal } from '../../data/animals';
import { getRareAnimals } from '../../helpers/animals';

const TrackingForm = () => {
    const { tracking, addToTracking } = useContext(TrackingContext);

    // Find rare animals that we're not already tracking
    const options = getRareAnimals().filter((a: IAnimal) => !tracking.find((t: IAnimal) => t.name === a.name))
    .sort((a: IAnimal, b: IAnimal) => a.name > b.name ? 1 : -1)

    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    const onAddToTracking = (data: any) => {
        const { name } = data;
        const animal = animals.find((a: IAnimal) => a.name === name);

        if (animal) {
            addToTracking(animal);
            setValue('name', '');
        }
    };

    return (
        <Box my={2}>
            <form onSubmit={handleSubmit(onAddToTracking)}>
                <HStack>
                    <FormControl isInvalid={!!errors['name']}>
                        <Select {...register('name', { required: true })} backgroundColor="chakra-body-bg">
                            <option value="">Select an Animal</option>
                            {options.map((a: IAnimal) => <option value={a.name} key={a.name}>{a.name}</option>)}
                        </Select>

                    </FormControl>
                    <IconButton aria-label="Add to Tracking" icon={<FaPlus />} variant="outline" backgroundColor="chakra-body-bg" onClick={handleSubmit(onAddToTracking)} />
                </HStack>
            </form>
        </Box>
    );
};

export default TrackingForm;