export interface IEorzeaTime {
    minute: number;
    hour: number;
}

export const getEorzeaTime = (date: Date = new Date()) => {
    const localEpoch = date.getTime();
    const eorzeaEpoch = localEpoch * 20.571428571428573;

    const minute = Math.floor((eorzeaEpoch / (1000 * 60)) % 60);
    const hour = Math.floor((eorzeaEpoch / (1000 * 60 * 60)) % 24);

    return { minute, hour };
};

export const getMsToNextEorzeaHour = () => {
    const currentHour = (new Date().getTime() / 1000) / 175;
    const nextHourStart = Math.ceil(currentHour);
    const timeToNextHour = (nextHourStart * 175 * 1000) - new Date().getTime();
    
    return timeToNextHour;
}

export const getStartOfEorzeaHour = (time: Date = new Date()) => {
    const currentHour = (time.getTime() / 1000) / 175;
    const startOfHour = Math.floor(currentHour);
    
    return new Date(startOfHour * 175 * 1000);
}

export const getIsSoon = (target: Date, threshold = 300) => {
    const difference = (target.getTime() / 1000) - (new Date().getTime() / 1000)
    return difference <= threshold && difference > 0;
}