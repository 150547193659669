export interface IMaterial {
    name: string;
    icon?: string;
}

export default [
    { name: 'Carapace', icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/materials/Carapace.png' },
    { name: 'Claw', icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/materials/Claw.png' },
    { name: 'Egg', icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/materials/Egg.png' },
    { name: 'Fang', icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/materials/Fang.png' },
    { name: 'Feather', icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/materials/Feather.png' },
    { name: 'Fleece', icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/materials/Fleece.png' },
    { name: 'Fur', icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/materials/Fur.png' },
    { name: 'Horn', icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/materials/Horn.png' },
    { name: 'Milk', icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/materials/Milk.png' },
    { name: 'Islewort' },
    { name: 'Island Hemp' },
    { name: 'Island Cotton Boll' },
    { name: 'Island Vine' },
    { name: 'Island Sugercane' },
    { name: 'Island Apple' },
    { name: 'Island Sap' },
    { name: 'Island Log' },
    { name: 'Island Palm Leaf' },
    { name: 'Island Palm Log' },
    { name: 'Island Branch' },
    { name: 'Island Sand' },
    { name: 'Island Clay' },
    { name: 'Island Tinsand' },
    { name: 'Island Stone' },
    { name: 'Island Copper Ore' },
    { name: 'Island Rock Salt' },
    { name: 'Island Quartz' },
    { name: 'Island Iron Ore' },
    { name: 'Island Limestone' },
    { name: 'Island Leucogranite '},
    { name: 'Island Coral' },
    { name: 'Island Jellyfish '},
    { name: 'Island Clam' },
    { name: 'Islefish' },
    { name: 'Island Laver' },
    { name: 'Island Squid' },
] as IMaterial[];