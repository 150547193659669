import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';

const GettingStarted = () => (
    <Flex direction="column" flexGrow={1}>
        <Box pb={6}>
            <Heading as="h3" size="md" pb={2}>Accessing the Island Sanctuary</Heading>
            <Text pb={2}>There is technically no level requirement to unlock the Island Sanctuary — the necessary quest is available at level 1 — but you will need to have completed the main story quest line from
                6.0 (Endwalker), meaning if you're just starting out your journey in FFXIV today, you've got quite the road ahead of you.
            </Text>

            <Text pb={2}>
                Once you've completed the Endwalker story, you can unlock the island sanctuary by starting the quest <Text as="b">Seeking Sanctuary</Text> from the <Text as="b">Clueless Crier </Text>
                in <Text as="b">Old Sharlayan (x11.9, y11.0)</Text>. This is a short quest that will take you almost directly to the island by way of <Text as="b">Baldin</Text> in <Text as="b">Lower La Noscea (x24.9, y34.8) </Text>
                (just look for the mob of people near the Aetheryte) and introduce you to some key mechanics before leaving you on your own to explore and start building.
            </Text>

            <Text pb={2}>
                Note that the island sanctuary is an instanced zone, similar to Bozja, meaning you'll be kicked out after 30 minutes of inactivity. If you don't want to wait, however, you can use the teleport or return skills,
                or for the hardcore roleplayers, there's a boat available at <Text as="b">x9.2, y28.3</Text> that will ferry you back to Lower La Noscea. Unfortunately, because the island sanctuary is an instance, there's
                no way to teleport back directly — you'll need to return to Baldin each time.
            </Text>
        </Box>
    </Flex>
);

export default GettingStarted;