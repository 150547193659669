import { Flex, Heading, Tab, TabList, TabPanel, TabPanels, Tabs, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import BasicMechanics from './BasicMechanics';
import GettingStarted from './GettingStarted';

const tabs = ['getting-started', 'basic-mechanics'];

const Guide = () => {
    const startingIndex = tabs.indexOf(window.location.hash.replace('#', ''));
    const startingTab = startingIndex === -1 ? 0 : startingIndex;
    const [isSmallDevice] = useMediaQuery('(max-width: 768px)');

    return (
        <Flex direction="column" flexGrow={1}>
            <PageHeader title="Guide" />
            <Tabs onChange={(index: number) => { window.location.hash = `#${tabs[index]}`; }} defaultIndex={startingTab}>
                <TabList>
                    <Tab>Getting Started</Tab>
                    <Tab>Basic Mechanics</Tab>
                </TabList>

                <TabPanels w={isSmallDevice ? '100%' : '80%'}>
                    <TabPanel px={isSmallDevice ? 0 : 4}>
                        <GettingStarted />
                    </TabPanel>
                    <TabPanel px={isSmallDevice ? 0 : 4}>
                        <BasicMechanics />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Flex>
    );
};

export default Guide;