import React, { useContext } from 'react';
import { Table, Thead, Tbody, Tr, Td, Link, HStack, Image, Text, useMediaQuery, IconButton, Flex, Heading, useColorModeValue } from '@chakra-ui/react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { CollectionContext, ICollectionItem } from '../../../contexts/CollectionContext';
import { AnimalInfoModalContext } from '../../../contexts/AnimalInfoModalContext';
import animals, { IAnimal } from '../../../data/animals';

const AnimalsPanel = () => {
    const { collection, getCollectionCount, addToCollection, removeFromCollection } = useContext(CollectionContext);
    const { showAnimalInfoModal } = useContext(AnimalInfoModalContext);
    const [isSmallDevice] = useMediaQuery('(max-width: 768px)');
    const alertColor = useColorModeValue('red.600', 'red.300');

    const sorted = collection.sort((a: ICollectionItem, b: ICollectionItem) => a.animalName > b.animalName ? 1 : -1);

    const onClickAnimal = (animal: IAnimal) => {
        if (animal) {
            showAnimalInfoModal(animal);
        }
    };

    const getPastureLevel = () => {
        const size = getCollectionCount();
        return size <= 5 ? 1 : size <= 10 ? 2 : 3;
    }

    return (
        <Flex direction="column" grow={1} mb={4}>
            <HStack mb={2} alignItems="flex-end">
                <Heading as="h3" size="md">Pasture</Heading>
                <Text fontSize="sm" color={getCollectionCount() > 20 ? alertColor : 'chakra-body-text'}>(Level {getPastureLevel()})</Text>
            </HStack>
            {collection.length ?
                <Table size="sm">
                    <Tbody>
                        {sorted.map((i: ICollectionItem) => {
                            const animal = animals.find((a: IAnimal) => a.name === i.animalName);
                            if (!animal) return null;

                            return (<Tr key={i.animalName}>
                                <Td>
                                    <Link textDecoration="underline" onClick={() => onClickAnimal(animal)}>
                                        <HStack>{animal.icon && <Image src={animal.icon} boxSize={isSmallDevice ? 8 : 10} borderRadius="full" />}<Text fontSize={isSmallDevice ? 'sm' : 'md'}>{animal.name}</Text></HStack>
                                    </Link>
                                </Td>
                                <Td>
                                    <Text fontSize="md">{i.count}</Text>
                                </Td>
                                <Td>
                                    <HStack>
                                        <IconButton icon={<FaMinus />} size="sm" onClick={() => removeFromCollection(i.animalName)} aria-label="Remove animal" />
                                        <IconButton icon={<FaPlus />} size="sm" onClick={() => addToCollection(i.animalName)} aria-label="Add animal" />
                                    </HStack>
                                </Td>
                            </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
                :
                <Text as="i">You haven't collected any animals yet!</Text>
            }
        </Flex>
    );
};

export default AnimalsPanel;
