import { Flex } from '@chakra-ui/react';
import React from 'react';
import AdsenseUnit from '../components/AdsenseUnit/AdsenseUnit';
import TrackingPanel from '../components/TrackingPanel/TrackingPanel';

const Sidebar = () => (
    <>
        <TrackingPanel />

        <Flex direction="column" grow={1} marginTop={4}>
            <AdsenseUnit slot="3559441875" />
        </Flex>
    </>
);

export default Sidebar;