import { Flex, Heading, Table, Tbody, useColorModeValue, FormControl, FormLabel, Switch } from '@chakra-ui/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import useSound from 'use-sound';
import { IRareAnimal, RareAnimalsContext } from '../../contexts/RareAnimalsContext';
import { SanctuaryConditionsContext } from '../../contexts/SanctuaryConditionsContext';
import { TrackingContext } from '../../contexts/TrackingContext';
import { IAnimal } from '../../data/animals';
import { getIsSoon } from '../../helpers/time';
import TrackingForm from './TrackingForm';
import TrackingRow from './TrackingRow';

enum EventType {
    Starting,
    Ending,
}

interface IEvent {
    animalName: string;
    eventTime: Date;
    eventType: EventType,
}

const getNextEventTime = (trackingItem: IRareAnimal) => {
    const { forecasts } = trackingItem;
    const { startsAt, endsAt } = forecasts[0];
    const now = new Date();

    if (now < startsAt) return { time: startsAt, type: EventType.Starting };

    // Fall back to when it leaves
    return { time: endsAt, type: EventType.Ending };
};

const getEventTimes = (trackingItem: IRareAnimal[]): IEvent[] => {
    const eventTimes: IEvent[] = [];
    trackingItem.forEach((t: IRareAnimal) => {
        const { time: eventTime, type: eventType } = getNextEventTime(t);
        eventTimes.push({ animalName: t.animal.name, eventTime, eventType });
    });

    return eventTimes;
};

const getEventTime = (eventTimes: IEvent[], animalName: string) => {
    return eventTimes.find((t: IEvent) => t.animalName === animalName)?.eventTime ||
        new Date(new Date().setFullYear(new Date().getFullYear() + 1));
};

const getAlarms = (eventTimes: IEvent[]) => {
    const alarms: IEvent[] = [];

    eventTimes.forEach((e: IEvent) => {
        const { eventTime, eventType } = e;

        if (eventType === EventType.Starting && getIsSoon(eventTime, 1)) {
            alarms.push(e);
        }
    });

    return alarms;
};

const getIsCurrentlyActive = (trackingItem: IRareAnimal) => {
    const { forecasts } = trackingItem;
    const { startsAt, endsAt } = forecasts[0];

    const now = new Date();

    return now >= startsAt && now < endsAt;
};

const TrackingPanel = () => {
    const alarmsRef = useRef<IEvent[]>([]);
    const { tracking } = useContext(TrackingContext);
    const { rareAnimals } = useContext(RareAnimalsContext);
    const [trackingItems, setTrackingItems] = useState<IRareAnimal[]>([]);
    const [doPlayAlarms, setDoPlayAlarms] = useState<boolean>(JSON.parse(localStorage.getItem('doPlayAlarms') || 'false'));
    const [play] = useSound(
        'https://ffxiv-sanctuary.s3.amazonaws.com/sounds/chime.mp3',
        { volume: 1.0 }
    );

    const playAlarm = play;

    // Force render updates
    useContext(SanctuaryConditionsContext);

    // Retrieve forecasts for the tracked animals
    useEffect(() => {
        setTrackingItems(rareAnimals.filter((a: IRareAnimal) => !!tracking.find((t: IAnimal) => a.animal.name === t.name)));
    }, [rareAnimals, tracking]);

    const eventTimes = getEventTimes(trackingItems);
    const sortedTrackingItemsByName = trackingItems.sort((a: IRareAnimal, b: IRareAnimal) => a.animal.name < b.animal.name ? 1 : -1); // TODO
    const sortedTrackingItemsByTime = sortedTrackingItemsByName.sort((a: IRareAnimal, b: IRareAnimal) => getEventTime(eventTimes, a.animal.name) > getEventTime(eventTimes, b.animal.name) ? 1 : -1);

    const activeItems = sortedTrackingItemsByTime.filter((i: IRareAnimal) => getIsCurrentlyActive(i));
    const upcomingItems = sortedTrackingItemsByTime.filter((i: IRareAnimal) => !getIsCurrentlyActive(i));

    const alarms = getAlarms(eventTimes);
    const alarmsStr = JSON.stringify(alarms);

    useEffect(() => {
        const alarms = JSON.parse(alarmsStr);

        let hasNewAlarm = false;
        alarms.forEach((e: IEvent) => { // Check for new animals
            if (!alarmsRef.current.find((a: IEvent) => e.animalName === a.animalName)) {
                hasNewAlarm = true;
            }
        });

        if (hasNewAlarm && doPlayAlarms) {
            playAlarm();
        }

        alarmsRef.current = alarms;
    }, [alarmsStr, doPlayAlarms, playAlarm]);

    const onToggleAlarms = () => {
        setDoPlayAlarms((prev: boolean) => {
            const newValue = !prev;
            if (newValue) playAlarm();

            localStorage.setItem('doPlayAlarms', newValue.toString());
            return newValue;
        });
    };

    return (
        <>
            <Flex bg={useColorModeValue('gray.100', 'gray.900')} borderRadius="md" p={4} direction="column">
                <Heading as="h3" size="md">Tracking</Heading>
                <TrackingForm />
                <FormControl display='flex' alignItems='center' justifyContent="flex-end" mb={2}>
                    <FormLabel htmlFor='play-alarms' mx={2} ml={0} mt={1} mb={1}>
                        Play Alarms
                    </FormLabel>
                    <Switch id='play-alarms' onChange={onToggleAlarms} isChecked={doPlayAlarms} />
                </FormControl>

                <Table variant="simple" backgroundColor="chakra-body-bg" size="sm" borderRadius="md">
                    <Tbody>
                        {[...activeItems, ...upcomingItems].map((a: IRareAnimal, i: number) =>
                            <TrackingRow
                                key={i}
                                isFirstItem={!i}
                                isLastItem={i === trackingItems.length - 1}
                                trackingItem={a}
                                nextEventTime={getEventTime(eventTimes, a.animal.name)}
                                isActive={getIsCurrentlyActive(a)}
                                hasAlarm={!!alarmsRef.current.find((e: IEvent) => e.animalName === a.animal.name)}
                            />
                        )}
                    </Tbody>
                </Table>
            </Flex>
        </>
    );
};

export default TrackingPanel;