import { Box, Flex, Heading, ListItem, UnorderedList, useMediaQuery, Text, Link } from '@chakra-ui/react';
import React from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';

const Changelog = () => {
    const [isSmallDevice] = useMediaQuery('(max-width: 768px)');

    return (
        <Flex direction="column" flexGrow={1} width="10">
            <PageHeader title="Changelog" />

            <Box pl={4} pb={6}>
                <Text fontSize={isSmallDevice ? 'sm' : 'md'}>For bug reports or feature requests, please reach out to <Link href="mailto:support@ffxiv-sanctuary.com">support@ffxiv-sanctuary.com</Link>.</Text>
                <Text fontSize={isSmallDevice ? 'sm' : 'md'}>Thank you to <Link href="https://discord.gg/thebalanceffxiv">The Balance community</Link> for all your hard work collecting animal and node information.</Text>
            </Box>

            <Box pl={4} pb={6}>
                <Heading as="h3" size="md" pb={2}>January 16th, 2023</Heading>
                <UnorderedList fontSize={isSmallDevice ? 'sm' : 'md'}>
                    <ListItem>Added indicator to animals that require flight to reach.
                    </ListItem>
                </UnorderedList>
            </Box>

            <Box pl={4} pb={6}>
                <Heading as="h3" size="md" pb={2}>January 11th, 2023</Heading>
                <UnorderedList fontSize={isSmallDevice ? 'sm' : 'md'}>
                    <ListItem>New animals from patch 6.3 have been added to the tracker, including Griffin, Tiger of Paradise, and Morbol Seedling.
                    </ListItem>
                </UnorderedList>
            </Box>

            <Box pl={4} pb={6}>
                <Heading as="h3" size="md" pb={2}>January 10th, 2023</Heading>
                <UnorderedList fontSize={isSmallDevice ? 'sm' : 'md'}>
                    <ListItem>FFXIV Patch 6.3 was released today with Sanctuary updates, including new gathering nodes and items. These are now visible in the gathering tool.
                    </ListItem>
                </UnorderedList>
            </Box>

            <Box pl={4} pb={6}>
                <Heading as="h3" size="md" pb={2}>November 11th, 2022</Heading>
                <UnorderedList fontSize={isSmallDevice ? 'sm' : 'md'}>
                    <ListItem>Corrected drop information for Ground Squirrel.</ListItem>
                    <ListItem>Minor performance improvements. More to come ahead of 6.3 Sanctuary updates.</ListItem>
                </UnorderedList>
            </Box>

            <Box pl={4} pb={6}>
                <Heading as="h3" size="md" pb={2}>September 18th, 2022</Heading>
                <UnorderedList fontSize={isSmallDevice ? 'sm' : 'md'}>
                    <ListItem>Added a guide section.</ListItem>
                    <ListItem>Fixed an issue where incorrect drops were being shown for Island Doe.</ListItem>
                </UnorderedList>
            </Box>

            <Box pl={4} pb={6}>
                <Heading as="h3" size="md" pb={2}>September 16th, 2022</Heading>
                <UnorderedList fontSize={isSmallDevice ? 'sm' : 'md'}>
                    <ListItem>Added an interactive gathering map.</ListItem>
                    <ListItem>Various UI adjustments.</ListItem>
                </UnorderedList>
            </Box>

            <Box pl={4} pb={6}>
                <Heading as="h3" size="md" pb={2}>September 13th, 2022</Heading>
                <UnorderedList fontSize={isSmallDevice ? 'sm' : 'md'}>
                    <ListItem>Added a Collection tab to the animals page.</ListItem>
                    <ListItem>Removed handling for bugged animals as it no longer seems to be necessary as of <Link href="https://na.finalfantasyxiv.com/lodestone/topics/detail/534a3f622078eac6d9f00f1b24689e1feb506f87">6.21</Link>.</ListItem>
                </UnorderedList>
            </Box>

            <Box pl={4} pb={6}>
                <Heading as="h3" size="md" pb={2}>September 12th, 2022</Heading>
                <UnorderedList fontSize={isSmallDevice ? 'sm' : 'md'}>
                    <ListItem>Added alarms to the tracking sidebar.</ListItem>
                    <ListItem>Fixed an issue where some animals would appear in the tracker to be available for longer than they were.</ListItem>
                    <ListItem>Various UI adjustments.</ListItem>
                </UnorderedList>
            </Box>

            <Box pl={4} pb={6}>
                <Heading as="h3" size="md" pb={2}>September 11th, 2022</Heading>
                <UnorderedList fontSize={isSmallDevice ? 'sm' : 'md'}>
                    <ListItem>Added animal tracking sidebar.</ListItem>
                    <ListItem>While investigating an issue where certain active animals would appear to reset at the beginning of each hour,
                        I ended up refactoring the forecasting logic, resulting in significantly improved performance and fewer visual glitches.
                    </ListItem>
                </UnorderedList>
            </Box>

            <Box pl={4}>
                <Heading as="h3" size="md" pb={2}>September 9th, 2022</Heading>
                <UnorderedList fontSize={isSmallDevice ? 'sm' : 'md'}>
                    <ListItem>Added Common Animals tab to the Animal Tracker.</ListItem>
                    <ListItem>Decided to start logging changes.</ListItem>
                </UnorderedList>
            </Box>
        </Flex>
    );
};

export default Changelog;