import { Box, Flex, Heading, Text, Link } from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const BasicMechanics = () => (
    <Flex direction="column" flexGrow={1}>
        <Box pb={6}>
            <Heading as="h3" size="md" pb={2}>Gathering Resources</Heading>
            <Text pb={2}>After talking to Baldin, you'll be transported to a beautiful but not-too-accomodating island. The first thing you'll want to do is build a shelter for yourself, henceforth known as the Cozy Cottage. In order to accomplish this,
                a mammet named Felicitous Furball will task you with gathering Island Branches, Island Stones, and Island Palm leaves.</Text>

            <Text pb={2}>Unlike normal gathering in FFXIV, you don't need to level or even swap into gathering jobs — you'll have access to most nodes as any job, at any level, as soon as you unlock the island's special gathering mode. Note, however, that
                you will not be able to access all gatherable resources right away: most nodes provide a common resource to novice settlers and a second only once you've upgraded your tools. Bluish Rocks, for example, provide Island Stone, which you'll need to
                complete Felicitous Furball's quest, but when your settlement reaches level 3 and you've crafted the Stone Hammer from the island's special crafting menu, they'll begin to drop Island Copper Ore as well.</Text>

            <Text pb={2}>As your sanctuary reaches higher levels and you need more specialized resources, finding the appropriate node can become daunting. The Gathering Log will suggest an area to search, but most nodes are found scattered
                throughout the island, and often the location suggestion is not the most convenient place to look. To help with this, I've provided an <Link as={RouterLink} to="/gathering" textDecoration="underline">interactive gathering map</Link> to
                help you locate whichever resources you're looking for.</Text>

            <Text pb={6}><Text as="b">Important note on node respawns:</Text> it may come as a surprise to players who aren't familiar with FFXIV's normal gathering system that nodes do not respawn on a timer. Instead, the only way to force
                a node to respawn is to continue gathering. After a total of 11 nodes have been harvested, nodes will respawn one by one in the order that you harvested them as you continue gathering resources. This means you'll generally want to
                target multiple materials in the same areas, since clusters of a single material are usually too small to respawn from gathering from them on their own. </Text>

            <Heading as="h3" size="md" pb={2}>Capturing Animals</Heading>
            <Text pb={2}>While out gathering resources, you'll likely notice a variety of animals roaming the wilds. There's no need to bring a healer — all of the wildlife on your island sanctuary is peaceful, regardless of how ferocious
                it might look. They do, however, provide precious resources that can't be acquired by gathering, so you'll want to capture some animals sooner than later. In order to do this, you'll first need to talk to the Creature Comforter mammet,
                who will clear some wilderness to construct a pasture, allowing you to begin collecting any animals you come across.</Text>

            <Text pb={2}>Animals on the island sanctuary come in three easy-to-remember sizes: small, medium, and large. To capture an animal, first craft a makeshift net with the crafting tool
                in the island menu. Once you've got one in your inventory, switch to capture mode and select the makeshift net as the item. You can now attempt to capture a small animal by using the action button while targeting it. Note that
                successful capture is not guaranteed, and you may end up losing the required item, so it's a good idea to stock up. Initially, you'll only be able to capture small animals by using a Makeshift Net, but when your sanctuary
                reaches level 3, you'll gain access to the Makeshift Restraint, which allows you to capture medium animals, and finally at level 8, you'll be able to capture large animal with the Makeshift Soporific.</Text>

            <Text pb={2}>Most of the animals you'll commonly encounter are available to capture at any time, but most of the creatures offered by the new sanctuary feature are only available during certain times of day, certain weather conditions,
                or sometimes a combination of both. For a breakdown of when each animal is available, check the <Link as={RouterLink} to="/animal-tracker" textDecoration="underline">animals section</Link> of this website. If you're browsing on the desktop
                version, you can also track specific animals from the Tracking sidebar tool while you're reading this guide.</Text>

            <Text pb={6}>Once you've captured an animal, it will appear back in your pasture and will be harvestable once daily (after server reset time) for their leavings.
                You'll be able to identify animals that are available for harvest by the glowing effect surrounding them. In order to harvest an animal, switch to gathering mode and use the action button while the animal is selected. For the best chance
                of receiving a rare drop, it's important to keep your animals fed and happy. In order to do this, switch to feed mode, select a food, and use the action button with the animal selected.</Text>

            <Heading as="h3" size="md" pb={2}>Farming</Heading>
            <Text pb={2}>So what do you feed your animals? The most readily available option is to craft Island Sweetfeed from the apples you can harvest from Island Apple Trees scattered throughout the wilds. This is the poorest quality food,
                however, and as your pasture grows, it may become a hassle to go out gathering apples for all your animals. If you want to keep your animals fed and happy, you'll want to grow your own food.</Text>

            <Text pb={2}>Follow the tutorial quests and you'll eventually unlock some farm land. In order to plant seeds, you'll need to switch to Sow mode from the island menu and select a seed as the item to use. Four different seeds can be acquired by
                gathering: Island Cabbage Seeds, Island Pumpkin Seeds, Island Parsnip Seeds, and Island Popoto Set. Any of these will work for crafting animal food, although ideally, you'll want to plant two different types so you're able to craft
                Premium Island Greenfeed. When you reach sanctuary level 3, you'll be able to purchase six new types of seeds from the mammet next to the farm in exchange for Seafarer's Cowries. These premium seeds can be used as crafting components
                as well as for animal food.</Text>

            <Text pb={2}>Once you've planted your seeds, you'll need to keep them hydrated as well. Switch to the watering mode and use the action button on each crop to water them. Ideally, your plants will reach maturity after 48 real life hours,
                but the soil will become dry after 36 hours if left unwatered, at which point they'll stop growing. Note that if you're present on the island while it's raining, that will count as watering. A good rule of thumb is simply to water
                your plants or observe them being rained on once a day. Mature plants will yield 5 of their associated item and will need to be replanted after harvesting.</Text>
        </Box>
    </Flex>
);

export default BasicMechanics;