import React, { CSSProperties, useCallback, useRef, useState } from 'react';

export enum Animations {
    PulsateFwd = 'pulsate-fwd',
    ShakeHorizontal = 'shake-horizontal',
}

export enum Curves {
    Linear = 'linear',
    Ease = 'ease',
    EaseIn = 'ease-in',
    EaseOut = 'ease-out',
    EaseInOut = 'ease-in-out',
    StepStart = 'step-start',
    StepEnd = 'step-end',
}

interface IRunAnimationParams {
    curve?: Curves | string;
    delay?: number;
    duration?: number;
    iterations?: number | 'infinite';
    name: Animations | string;
    onComplete?: () => void;
}

interface IAnimatedElementProps {
    children: JSX.Element | JSX.Element[] | string;
}

// Returns an element as well as functions to start and stop animations.
// To animate an element, wrap it in the returned element and call the animation functions as needed.

// e.g. const { Animated } = useAnimated();
// <Animated><h1>Animate Me!</h1></Animated>
const useAnimated = () => {
    const [cssProperties, setCSSProperties] = useState<CSSProperties>();
    const timeoutRef = useRef<number>();

    const el = useCallback(({ children }: IAnimatedElementProps) => (
        <div style={{ ...cssProperties, display: 'inline-block' }}>{children}</div>
    ), [cssProperties]);

    // Use when you need a variable amount of Animated elements by creating an array of useAnimated
    // hooks and running this instead of assigning the element for each.
    const renderAnimated = useCallback((children: JSX.Element | JSX.Element[] | string) => {
        const Animated = el;
        return <Animated>{children}</Animated>;
    }, [cssProperties]);

    const runAnimation = ({
        curve = 'ease',
        delay = 0,
        duration = 1,
        iterations = 1,
        name,
        onComplete
    }: IRunAnimationParams) => {
        setCSSProperties({
            animationDelay: `${delay}s`,
            animationDuration: `${duration}s`,
            animationIterationCount: iterations,
            animationName: name,
            animationTimingFunction: curve,
        } as CSSProperties);

        clearTimeout(timeoutRef.current);
        if (iterations !== 'infinite') {
            timeoutRef.current = window.setTimeout(() => {
                stopAnimation();
                if (onComplete) {
                    onComplete();
                }
            }, (delay * 1000) + (duration * iterations * 1000));
        }
    };

    const stopAnimation = () => {
        setCSSProperties({} as CSSProperties);
        clearTimeout(timeoutRef.current);
    };

    return {
        Animated: el,
        renderAnimated,
        runAnimation,
        stopAnimation,
    };
};

export default useAnimated;
