import { HStack, Link, Td, Tr, Image, VStack, Box, Text, Button, useToast } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { CollectionContext } from '../../../contexts/CollectionContext';
import { IAnimal, IAnimalDrop } from '../../../data/animals';
import AnimalDrop from './AnimalDrop';

interface IDesktopCommonAnimalRowProps {
    animal: IAnimal;
    openAnimalModal: (animal: IAnimal) => void;
}

const DesktopCommonAnimalRow = ({ animal, openAnimalModal }: IDesktopCommonAnimalRowProps) => {
    const { addToCollection } = useContext(CollectionContext);
    const toast = useToast();
    const { name, icon, location, size } = animal;

    const onClickCollect = () => {
        addToCollection(animal.name);
        toast({
            title: 'Animal Collected',
            description: `${animal.name} has been added to your collection!`,
            status: 'success',
            duration: 3000,
            isClosable: true,
        });
    };

    return (
        <Tr fontSize='sm'>
            <Td>
                <Link textDecoration="underline" onClick={() => openAnimalModal(animal)}>
                    <HStack>{icon && <Image src={icon} boxSize="10" borderRadius="full" />}<Text fontSize="md">{name}</Text></HStack>
                </Link>
            </Td>

            <Td><Link onClick={() => openAnimalModal(animal)}>x{location.x}, y{location.y}</Link></Td>
            <Td>{size}</Td>
            <Td>
                <VStack alignItems="flex-end">
                    {(animal?.drops || []).map((d: IAnimalDrop) => <Box key={d.name}><AnimalDrop drop={d} /></Box>)}
                </VStack>
            </Td>
            <Td>
                <Button onClick={onClickCollect} size="sm">Collect</Button>
            </Td>
        </Tr>
    );
};

export default DesktopCommonAnimalRow;