import React, { useEffect, useRef } from 'react';
import { Box, Collapse, Flex, HStack, IconButton, Stack, Text, useColorModeValue, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { navigationItems } from '../../router';

import { ColorModeSwitcher } from './ColorModeSwitcher';
import NavLink from './NavLink';
import useKeyDownAlerter from '../../hooks/useKeyDownAlerter';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
import { useLocation } from 'react-router-dom';
import SanctuaryConditions from './SanctuaryConditions';

const Navbar = () => {
    const [isMaxWidth] = useMediaQuery('(min-width: 1280px)');
    const { isOpen, onToggle, onClose } = useDisclosure();
    const isOpenRef = useRef(false);
    const navRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        isOpenRef.current = isOpen;
    }, [isOpen]);

    // Close hamburger menu on escape or outside click
    const onCloseMobileNav = () => {
        if (isOpenRef.current) {
            onClose();
        }
    };

    useKeyDownAlerter('Escape', () => { onCloseMobileNav(); });
    useOutsideAlerter(navRef, () => { onCloseMobileNav(); });

    // Close hamburger menu on route change
    const location = useLocation();
    useEffect(() => {
        onClose();
    }, [location, onClose]);

    const onClickHamburger = () => {
        onToggle();
    }

    return (
        <Flex grow={1} direction="column" bg={useColorModeValue('gray.100', 'gray.900')} borderRadius={isMaxWidth ? 'md' : 0} ref={navRef}>
            <Flex grow={1} px={4} minH={16} align="center" justifyContent="space-between">
                <IconButton
                    size={'md'}
                    icon={<HamburgerIcon />}
                    aria-label={'Open Menu'}
                    display={{ md: 'none' }}
                    onClick={onClickHamburger}
                />

                <HStack spacing={8} alignItems={'center'}>
                    <Box>
                        <Text fontFamily="heading">FFXIV Sanctuary</Text>
                    </Box>
                    <HStack
                        as={'nav'}
                        spacing={4}
                        display={{ base: 'none', md: 'flex' }}>
                        {navigationItems.map((item) => (
                            <NavLink key={item.href} route={item} />
                        ))}
                        <ColorModeSwitcher />
                    </HStack>
                </HStack>

                <HStack>
                    <SanctuaryConditions />
                </HStack>
            </Flex>
            <Collapse in={isOpen}>
                <Stack as={'nav'}>
                    {navigationItems.map((item) => (
                        <NavLink key={item.href} route={item} isHamburger />
                    ))}
                    <ColorModeSwitcher isHamburger />
                </Stack>
            </Collapse>
        </Flex>
    );
};

export default Navbar;
