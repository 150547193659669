import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { IMapData } from './Gathering';

interface ILegendProps {
    mapData: IMapData[];
}

const Legend = ({ mapData }: ILegendProps) => (
    <VStack alignItems="flex-stand">
        {mapData.map((d: IMapData) => (
            <HStack key={d.node.name}>
                <Box h={4} w={4} minW={4} backgroundColor={d.color} borderWidth={1} borderColor="black" borderRadius="full" />
                <Text fontSize="sm">{d.node.name} ({d.materials?.join(', ')})</Text>
            </HStack>
        ))}
    </VStack>
);

export default Legend;