import { Weather } from '../helpers/weather';

enum AnimalSize {
    Small = 'Small',
    Medium = 'Medium',
    Large = 'Large',
}

interface AnimalCoordinates {
    x: number,
    y: number
}

interface IAnimalConditions {
    appearsAt: number;
    leavesAt: number;
    requiresFlight?: boolean;
    weather?: Weather;
}

export interface IAnimalDrop {
    name: string;
    isRare?: boolean;
}

export interface IAnimal {
    name: string,
    size: AnimalSize,
    conditions: IAnimalConditions;
    location: AnimalCoordinates;
    icon?: string;
    drops?: IAnimalDrop[];
}

export default [
    {
        name: 'Glyptodon',
        size: AnimalSize.Medium,
        conditions: {
            appearsAt: 0,
            leavesAt: 3,
        },
        location: {
            x: 31,
            y: 11,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/Glyptodon.webp',
        drops: [
            { name: 'Claw' }, { name: 'Carapace', isRare: true },
        ],
    },
    {
        name: 'Island Billy',
        size: AnimalSize.Large,
        conditions: {
            appearsAt: 3,
            leavesAt: 6,
        },
        location: {
            x: 26,
            y: 22,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/IslandBilly.webp',
        drops: [
            { name: 'Horn' }, { name: 'Fleece', isRare: true },
        ],
    },
    {
        name: 'Lemur',
        size: AnimalSize.Small,
        conditions: {
            appearsAt: 6,
            leavesAt: 9,
        },
        location: {
            x: 20,
            y: 26,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/Lemur.webp',
        drops: [
            { name: 'Fur' }, { name: 'Claw', isRare: true },
        ],
    },
    {
        name: 'Star Marmot',
        size: AnimalSize.Small,
        conditions: {
            appearsAt: 9,
            leavesAt: 12,
        },
        location: {
            x: 15,
            y: 19,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/StarMarmot.webp',
        drops: [
            { name: 'Fur' }, { name: 'Claw', isRare: true },
        ],
    },
    {
        name: 'Apkallu of Paradise',
        size: AnimalSize.Small,
        conditions: {
            appearsAt: 12,
            leavesAt: 15,
        },
        location: {
            x: 19,
            y: 11,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/ApkalluOfParadise.webp',
        drops: [
            { name: 'Feather' }, { name: 'Egg', isRare: true },
        ],
    },
    {
        name: 'Dodo of Paradise',
        size: AnimalSize.Medium,
        conditions: {
            appearsAt: 15,
            leavesAt: 18,
        },
        location: {
            x: 16,
            y: 12,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/DodoOfParadise.webp',
        drops: [
            { name: 'Feather' }, { name: 'Egg', isRare: true },
        ],
    },
    {
        name: 'Island Stag',
        size: AnimalSize.Medium,
        conditions: {
            appearsAt: 18,
            leavesAt: 21,
        },
        location: {
            x: 20,
            y: 19,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/IslandStag.webp',
        drops: [
            { name: 'Fur' }, { name: 'Horn', isRare: true },
        ],
    },
    {
        name: 'Ornery Karakul',
        size: AnimalSize.Small,
        conditions: {
            weather: Weather.Fair,
        },
        location: {
            x: 20,
            y: 23,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/OrneryKarakul.webp',
        drops: [
            { name: 'Milk' }, { name: 'Fleece', isRare: true },
        ],
    },
    {
        name: 'Black Chocobo',
        size: AnimalSize.Medium,
        conditions: {
            weather: Weather.Clear,
        },
        location: {
            x: 13,
            y: 11,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/BlackChocobo.webp',
        drops: [
            { name: 'Feather' }, { name: 'Fur', isRare: true },
        ],
    },
    {
        name: 'Gold Back',
        size: AnimalSize.Large,
        conditions: {
            weather: Weather.Rain,
        },
        location: {
            x: 31,
            y: 28,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/GoldBack.webp',
        drops: [
            { name: 'Feather' }, { name: 'Egg', isRare: true },
        ],
    },
    {
        name: 'Grand Buffalo',
        size: AnimalSize.Large,
        conditions: {
            weather: Weather.Clouds,
        },
        location: {
            x: 12,
            y: 17,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/GrandBuffalo.webp',
        drops: [
            { name: 'Horn' }, { name: 'Milk', isRare: true },
        ],
    },
    {
        name: 'Yellow Coblyn',
        size: AnimalSize.Small,
        conditions: {
            weather: Weather.Fog,
        },
        location: {
            x: 27,
            y: 19,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/YellowCoblyn.webp',
        drops: [
            { name: 'Carapace' }, { name: 'Fang', isRare: true },
        ],
    },
    {
        name: 'Beachcomb',
        size: AnimalSize.Small,
        conditions: {
            weather: Weather.Rain,
            appearsAt: 0,
            leavesAt: 3,
        },
        location: {
            x: 17.8,
            y: 12.6,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/Beachcomb.webp',
        drops: [
            { name: 'Carapace' }, { name: 'Claw', isRare: true },
        ],
    },
    {
        name: 'Alligator',
        size: AnimalSize.Large,
        conditions: {
            weather: Weather.Showers,
            appearsAt: 6,
            leavesAt: 9,
        },
        location: {
            x: 17,
            y: 24,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/Alligator.webp',
        drops: [
            { name: 'Claw' }, { name: 'Fang', isRare: true },
        ],
    },
    {
        name: 'Goobbue',
        size: AnimalSize.Large,
        conditions: {
            weather: Weather.Clouds,
            appearsAt: 9,
            leavesAt: 12,
        },
        location: {
            x: 33,
            y: 16,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/Goobbue.webp',
        drops: [
            { name: 'Fang' }, { name: 'Claw', isRare: true },
        ],
    },
    {
        name: 'Paissa',
        size: AnimalSize.Medium,
        conditions: {
            weather: Weather.Fair,
            appearsAt: 12,
            leavesAt: 15,
            requiresFlight: true,
        },
        location: {
            x: 25,
            y: 28,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/Paissa.webp',
        drops: [
            { name: 'Claw' }, { name: 'Fleece', isRare: true },
        ],
    },
    {
        name: 'Twinklefleece',
        size: AnimalSize.Small,
        conditions: {
            weather: Weather.Fog,
            appearsAt: 18,
            leavesAt: 21,
        },
        location: {
            x: 22.1,
            y: 20.8,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/Twinklefleece.webp',
        drops: [
            { name: 'Fleece' }, { name: 'Fur', isRare: true },
        ],
    },
    {
        name: 'Lost Lamb',
        size: AnimalSize.Small,
        location: {
            x: 20,
            y: 23,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/LostLamb.webp',
        drops: [
            { name: 'Fleece' }, { name: 'Milk', isRare: true },
        ],
    },
    {
        name: 'Opo-Opo',
        size: AnimalSize.Small,
        location: {
            x: 20,
            y: 26,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/OpoOpo.webp',
        drops: [
            { name: 'Claw' }, { name: 'Fur', isRare: true },
        ],
    },
    {
        name: 'Apkallu',
        size: AnimalSize.Small,
        location: {
            x: 19,
            y: 11,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/Apkallu.webp',
        drops: [
            { name: 'Fleece' }, { name: 'Egg', isRare: true },
        ],
    },
    {
        name: 'Ground Squirrel',
        size: AnimalSize.Small,
        location: {
            x: 15,
            y: 19,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/GroundSquirrel.webp',
        drops: [
            { name: 'Claw' }, { name: 'Fur', isRare: true },
        ],
    },
    {
        name: 'Coblyn',
        size: AnimalSize.Small,
        location: {
            x: 20,
            y: 13,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/Coblyn.webp',
        drops: [
            { name: 'Fang' }, { name: 'Carapace', isRare: true },
        ],
    },
    {
        name: 'Wild Dodo',
        size: AnimalSize.Medium,
        location: {
            x: 16,
            y: 12,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/WildDodo.webp',
        drops: [
            { name: 'Egg' }, { name: 'Feather', isRare: true },
        ],
    },
    {
        name: 'Island Doe',
        size: AnimalSize.Medium,
        location: {
            x: 21,
            y: 19,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/IslandDoe.webp',
        drops: [
            { name: 'Fur' }, { name: 'Milk', isRare: true },
        ],
    },
    {
        name: 'Chocobo',
        size: AnimalSize.Medium,
        location: {
            x: 20,
            y: 21,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/Chocobo.webp',
        drops: [
            { name: 'Fur' }, { name: 'Feather', isRare: true },
        ],
    },
    {
        name: 'Glyptodon Pup',
        size: AnimalSize.Medium,
        location: {
            x: 30,
            y: 11,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/GlyptodonPup.webp',
        drops: [
            { name: 'Carapace' }, { name: 'Claw', isRare: true },
        ],
    },
    {
        name: 'Aurochs',
        size: AnimalSize.Large,
        location: {
            x: 12,
            y: 17,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/Aurochs.webp',
        drops: [
            { name: 'Milk' }, { name: 'Horn', isRare: true },
        ],
    },
    {
        name: 'Island Nanny',
        size: AnimalSize.Large,
        location: {
            x: 26,
            y: 24,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/IslandNanny.webp',
        drops: [
            { name: 'Milk' }, { name: 'Horn', isRare: true },
        ],
    },
    {
        name: 'Blue Back',
        size: AnimalSize.Large,
        location: {
            x: 28,
            y: 28,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/BlueBack.webp',
        drops: [
            { name: 'Egg' }, { name: 'Feather', isRare: true },
        ],
    },
    {
        name: 'Griffin',
        size: AnimalSize.Large,
        conditions: {
            weather: Weather.Clear,
            appearsAt: 15,
            leavesAt: 18,
            requiresFlight: true,
        },
        location: {
            x: 15,
            y: 22,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/Griffin.webp',
        drops: [
            { name: 'Feather' }, { name: 'Claw', isRare: true },
        ],
    },
    {
        name: 'Tiger of Paradise',
        size: AnimalSize.Medium,
        conditions: {
            weather: Weather.Fair,
            appearsAt: 18,
            leavesAt: 21,
        },
        location: {
            x: 15,
            y: 14,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/TigerOfParadise.webp',
        drops: [
            { name: 'Fang' }, { name: 'Fur', isRare: true },
        ],
    },
    {
        name: 'Morbol Seedling',
        size: AnimalSize.Small,
        conditions: {
            weather: Weather.Clouds,
            appearsAt: 3,
            leavesAt: 6,
        },
        location: {
            x: 19,
            y: 19,
        },
        icon: 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/animals/MorbolSeedling.webp',
        drops: [
            { name: 'Carapace' }, { name: 'Fang', isRare: true },
        ],
    }
] as IAnimal[];