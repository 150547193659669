import { Flex, HStack, FormControl, Select, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, IconButton, Heading, useMediaQuery } from '@chakra-ui/react';
import { FaPlus } from 'react-icons/fa';
import { useForm, Controller } from 'react-hook-form';
import React, { useContext } from 'react';
import animals, { IAnimal } from '../../../data/animals';
import { CollectionContext } from '../../../contexts/CollectionContext';

const AddAnimalForm = () => {
    const { addToCollection } = useContext(CollectionContext);
    const [isSmallDevice] = useMediaQuery('(max-width: 768px)');
    const animalOptions = animals.map((a: IAnimal) => a.name).sort((a: string, b: string) => a > b ? 1 : -1);
    const { register, handleSubmit, control, reset, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            amount: 1,
        }
    });

    const onAddAnimal = (data: any) => {
        const { name, amount } = data;
        addToCollection(name, parseInt(amount));

        reset();
    };

    return (
        <Flex width="100%" mb={4} direction="column">
            <Heading as="h3" size="md" mb={2}>Add an Animal</Heading>
            <form onSubmit={handleSubmit(onAddAnimal)}>
                <HStack flexGrow={1}>

                    <FormControl isInvalid={!!errors['name']}>
                        <Select placeholder={isSmallDevice ? 'Animal' : 'Select an Animal'} {...register('name', { required: true })}>
                            {animalOptions.map((n: string) => <option key={n} value={n}>{n}</option>)}
                        </Select>
                    </FormControl>

                    <FormControl isInvalid={!!errors['amount']}>
                        <Controller
                            name="amount"
                            control={control}
                            rules={{
                                required: true,
                                min: 1,
                                max: 20,
                            }}
                            render={({ field: { ref, ...restField } }) => (
                                <NumberInput {...restField} defaultValue={1} min={1} max={20}>
                                    <NumberInputField ref={ref} name={restField.name} />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            )}
                        />
                    </FormControl>

                    <IconButton icon={<FaPlus />} aria-label="Add animal" onClick={handleSubmit(onAddAnimal)} />

                </HStack>
            </form>
        </Flex >
    );
};

export default AddAnimalForm;
