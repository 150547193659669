import { Box, Flex, Heading, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import AdsenseUnit from '../AdsenseUnit/AdsenseUnit';

interface IPageHeaderProps {
    title: string;
}

const PageHeader = ({ title }: IPageHeaderProps) => {
    const [isSmallDevice] = useMediaQuery('(max-width: 768px)');

    return (
        <Flex grow="1" justifyContent="space-between" alignItems="flex-end" mb={2} height={isSmallDevice ? 'auto' : '90px'}>
            <Box><Heading as="h2" size="xl">{title}</Heading></Box>
            {!isSmallDevice && <Flex grow="1" minWidth="730" height="90px !important" maxHeight="90px !important" justifyContent="flex-end">
                <AdsenseUnit slot="8670607251" isHeader />
            </Flex>}
        </Flex>
    );
};

export default PageHeader;
