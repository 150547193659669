import React, { memo } from 'react';
import { Text } from '@chakra-ui/react';

interface ITrackingRowAnimalNameProps {
    name: string;
    renderAnimated: (children: JSX.Element | JSX.Element[] | string) => void;
}

const TrackingRowAnimalName = ({ name, renderAnimated }: ITrackingRowAnimalNameProps) => {
    return (
        <>
            {renderAnimated(<Text fontSize="md">{name}</Text>)}
        </>
    );
};

export default memo(TrackingRowAnimalName);