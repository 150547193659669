import animals, { IAnimal } from '../data/animals';
import { getEorzeaTime, getStartOfEorzeaHour } from './time';
import { getWeatherChance, getWeatherFromChance } from './weather';

export interface IAnimalForecast {
    startsAt: Date;
    endsAt: Date;
}

export const getRareAnimals = () => {
    return animals.filter((a: IAnimal) => a.conditions && (a.conditions.appearsAt !== undefined || a.conditions.weather));
};

const hourIsInAnimalWindow = (hour: number, animal: IAnimal) => {
    const startEorzeaHour = animal.conditions?.appearsAt || 0;
    let endEorzeaHour = animal.conditions?.leavesAt || 24;

    const timeDoesWrap = endEorzeaHour < startEorzeaHour;

    if (timeDoesWrap) {
        hour += 24;
        endEorzeaHour += 24;
    }

    return hour >= startEorzeaHour && hour < endEorzeaHour;
};

const isAnimalActiveAtTime = (animal: IAnimal, time: Date) => {
    // Determine if the time is valid
    let { hour: currentEorzeaHour } = getEorzeaTime(time);
    const isInWindow = hourIsInAnimalWindow(currentEorzeaHour, animal);

    // Determine if the weather is valid
    const weatherChance = getWeatherChance(time);
    const weather = getWeatherFromChance(weatherChance);

    const isValidWeather = !animal.conditions.weather || weather === animal.conditions.weather;

    return isInWindow && isValidWeather;
};

export const getAnimalForecast = (animal: IAnimal, range: number = 1) => {
    const forecast: IAnimalForecast[] = [];

    let timeToCheck = getStartOfEorzeaHour(new Date());

    for (let i = 0; i < range; i++) {
        let startsAt: Date | undefined = undefined;
        let endsAt: Date | undefined;

        // Find the next active time
        while (!isAnimalActiveAtTime(animal, timeToCheck)) {
            timeToCheck = new Date(timeToCheck.getTime() + (175 * 1000));
        }

        if (!i) { // First iteration -- we need to trace this back to its appropriate start time
            while (!startsAt) {
                timeToCheck = new Date(timeToCheck.getTime() - (175 * 1000));
                if (!isAnimalActiveAtTime(animal, timeToCheck)) {
                    timeToCheck = new Date(timeToCheck.getTime() + (175 * 1000));
                    startsAt = timeToCheck;
                }
            }
        } else {
            startsAt = timeToCheck;
        }

        // Find the end time
        while (!endsAt) {
            timeToCheck = new Date(timeToCheck.getTime() + (175 * 1000));

            if (!isAnimalActiveAtTime(animal, timeToCheck)) {
                endsAt = timeToCheck;
            }
        }

        forecast.push({ startsAt, endsAt });
        timeToCheck = new Date(endsAt.getTime() + (175 * 1000));
    }

    return forecast;
};
