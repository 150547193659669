import React from 'react';
import {
    useColorMode,
    useColorModeValue,
    Switch,
    Link,
    HStack,
} from "@chakra-ui/react";

interface IColorModeSwitcherProps {
    isHamburger?: boolean;
}

export const ColorModeSwitcher = ({ isHamburger }: IColorModeSwitcherProps) => {
    const { toggleColorMode } = useColorMode();
    const isDark = useColorModeValue(true, false);

    return (
        <HStack>
            <Link
                px={isHamburger ? 4 : 2}
                py={isHamburger ? 4 : 2}
                rounded={isHamburger ? 'none' : 'md'}
                fontSize={isHamburger ? 'md' : 'sm'}
                _hover={{
                    textDecoration: 'none',
                    bg: useColorModeValue('gray.100', 'gray.700'),
                }}
                onClick={toggleColorMode}
            >
                Dark Mode
            </Link>
            <Switch onChange={toggleColorMode} isChecked={!isDark} />
        </HStack>
    );
};
