import { useEffect } from 'react';

export default function useKeyDownAlerter(key: string, onKeyDownAlert: () => void) {
    useEffect(() => {
        function handleKeyDown(event: KeyboardEvent) {
            if (event.code === key) {
                onKeyDownAlert();
            }
        }

        document.addEventListener('keydown', handleKeyDown, false);

        return () => {
            document.removeEventListener('keydown', handleKeyDown, false);
        };
    }, [key, onKeyDownAlert]);
}
