import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { IAnimal } from '../data/animals';
import { getAnimalForecast, getRareAnimals, IAnimalForecast } from '../helpers/animals';
import { getMsToNextEorzeaHour } from '../helpers/time';

export interface IRareAnimal {
    animal: IAnimal;
    forecasts: IAnimalForecast[];
}

interface IRareAnimalContext {
    forecastRange: number;
    setForecastRange: (newRange: number) => void;
    rareAnimals: IRareAnimal[];
}

interface IRareAnimalsProviderProps {
    children: JSX.Element | JSX.Element[];
}

export const RareAnimalsContext = createContext<IRareAnimalContext>({
    forecastRange: 1,
    setForecastRange: () => { },
    rareAnimals: [],
});

const RareAnimalsProvider = ({ children }: IRareAnimalsProviderProps) => {
    const [rareAnimals, setRareAnimals] = useState<IRareAnimal[]>([]);
    const [forecastRange, setForecastRange] = useState(1);
    const timeoutRef = useRef<number>();

    const updateAnimals = useCallback(() => {
        const rareAnimals: IRareAnimal[] = [];
        const animals = getRareAnimals();

        animals.forEach((animal: IAnimal) => {
            const forecasts = getAnimalForecast(animal, forecastRange);

            rareAnimals.push({
                animal, forecasts
            });
        });

        setRareAnimals(rareAnimals);
    }, [forecastRange]);

    const updateTimeout = useCallback(() => {
        const msToNextHour = getMsToNextEorzeaHour();

        timeoutRef.current = window.setTimeout(() => {
            updateAnimals();
            updateTimeout();
        }, msToNextHour);
    }, [updateAnimals]);

    useEffect(() => {
        updateAnimals();
        updateTimeout();

        return () => { clearTimeout(timeoutRef.current); };
    }, [updateAnimals, updateTimeout]);

    useEffect(() => {
        updateAnimals();
    }, [forecastRange, updateAnimals]);

    return (
        <RareAnimalsContext.Provider value={{ forecastRange, setForecastRange, rareAnimals }}>
            {children}
        </RareAnimalsContext.Provider>
    );
};

export default RareAnimalsProvider;