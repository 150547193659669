import { Box, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useColorModeValue, Image, Table, Tbody, Td, Tr, VStack, Flex, Icon, Tooltip } from '@chakra-ui/react';
import React, { memo } from 'react';
import { FaFeather } from 'react-icons/fa';
import { IAnimal, IAnimalDrop } from '../../data/animals';
import SanctuaryMap from '../SanctuaryMap/SanctuaryMap';
import AnimalDrop from './AnimalDrop';

interface IAnimalInfoModalProps {
    animal?: IAnimal;
    onClose: () => void;
}

const AnimalInfoModal = ({ animal, onClose }: IAnimalInfoModalProps) => {
    return (
        <Modal isOpen={!!animal} onClose={onClose} returnFocusOnClose={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader pt="3" pb="1">
                    <HStack>
                        {animal?.icon && <Image src={animal.icon} borderRadius="full" />}
                        <span>{animal?.name}</span>
                    </HStack>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box border="1px" borderColor={useColorModeValue('gray.100', 'gray.900')} borderRadius="md" overflow="hidden">
                        <SanctuaryMap markers={[{ coordinate: { x: animal?.location.x as number, y: animal?.location.y as number } }]} />
                    </Box>
                    <Box bg={useColorModeValue('gray.100', 'gray.900')} mt={2} borderRadius="md">
                        <Table size="sm" variant="unstyled">
                            <Tbody>
                                <Tr>
                                    <Td pl={4} pr={0} fontWeight="bold" textAlign="right" paddingRight="4">Location:</Td>
                                    <Td pl={0}>
                                        <Flex>
                                            x{animal?.location.x}, y{animal?.location.y}
                                            {animal?.conditions.requiresFlight && <Tooltip label="Flight required" bg="gray.900" color="gray.100"><Box ml="1"><Icon as={FaFeather} /></Box></Tooltip>}
                                        </Flex>
                                    </Td>
                                    <Td pl={0} fontWeight="bold" textAlign="right">Size:</Td><Td pl={0}>{animal?.size}</Td>
                                </Tr>
                                <Tr>
                                    <Td pl={4} pr={0} fontWeight="bold" textAlign="right" paddingRight="4">Time (ET):</Td><Td pl={0}>{animal?.conditions?.appearsAt !== undefined ? String(animal?.conditions?.appearsAt).padStart(2, '0') + ':00' : 'Any'}</Td>
                                    <Td pl={0} fontWeight="bold" textAlign="right">Weather:</Td><Td pl={0}>{animal?.conditions?.weather ? animal?.conditions?.weather : 'Any'}</Td>
                                </Tr>
                                <Tr>
                                    <Td pl={4} pr={0} fontWeight="bold" textAlign="right" paddingRight="4" verticalAlign="top">Drops:</Td>
                                    <Td pl={0} colSpan={3}>
                                        <VStack alignItems="flex-start">
                                            {(animal?.drops || []).map((d: IAnimalDrop) => <Box key={d.name}><AnimalDrop drop={d} /></Box>)}
                                        </VStack>
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

const areEqual = (oldProps: IAnimalInfoModalProps, newProps: IAnimalInfoModalProps) => {
    return JSON.stringify(oldProps.animal) === JSON.stringify(newProps.animal);
};

export default memo(AnimalInfoModal, areEqual);