import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AnimalTracker from './routes/animal-tracker/AnimalTracker';
import Changelog from './routes/changelog/Changelog';
import Gathering from './routes/gathering/Gathering';
import Guide from './routes/guide/Guide';

export interface INavItem {
	label: string;
	href: string;
}

export const navigationItems: INavItem[] = [
    {
        label: 'Guide',
        href: '/guide',
    },
    {
        label: 'Animals',
        href: '/animal-tracker',
    },
    {
        label: 'Gathering',
        href: '/gathering',
    },
    {
        label: 'Changelog',
        href: '/changelog',
    },
];

const Router = () => (
    <Routes>
        <Route path="/" element={<Navigate replace to="/guide" />} />
        <Route path="/guide" element={<Guide />} />
		<Route path="/animal-tracker" element={<AnimalTracker />} />
        <Route path="/gathering" element={<Gathering />} />
        <Route path="/changelog" element={<Changelog />} />
    </Routes>
);

export default Router;