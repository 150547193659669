import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import theme from './theme';
import Main from './layout/Main';
import SanctuaryConditionsProvider from './contexts/SanctuaryConditionsContext';
import TrackingProvider from './contexts/TrackingContext';
import RareAnimalsProvider from './contexts/RareAnimalsContext';
import AnimalInfoModalProvider from './contexts/AnimalInfoModalContext';
import CollectionProvider from './contexts/CollectionContext';

function App() {
    return (
        <BrowserRouter>
            <ColorModeScript />
            <ChakraProvider theme={theme}>
                <TrackingProvider>
                    <SanctuaryConditionsProvider>
                        <RareAnimalsProvider>
                            <AnimalInfoModalProvider>
                                <CollectionProvider>
                                    <Main />
                                </CollectionProvider>
                            </AnimalInfoModalProvider>
                        </RareAnimalsProvider>
                    </SanctuaryConditionsProvider>
                </TrackingProvider>
            </ChakraProvider>
        </BrowserRouter>
    );
}

export default App;
