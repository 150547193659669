import { Flex, Heading, Tab, TabList, TabPanel, TabPanels, Tabs, useMediaQuery } from '@chakra-ui/react';
import { useContext } from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import { CollectionContext } from '../../contexts/CollectionContext';
import AnimalCollection from './AnimalCollection/AnimalCollection';
import CommonAnimals from './CommonAnimals/CommonAnimals';
import RareAnimals from './RareAnimals/RareAnimals';

const tabs = ['rare', 'common', 'collection'];

const AnimalTracker = () => {
    const [isSmallDevice] = useMediaQuery('(max-width: 768px)');
    const { getCollectionCount } = useContext(CollectionContext);

    const startingIndex = tabs.indexOf(window.location.hash.replace('#', ''));
    const startingTab = startingIndex === -1 ? 0 : startingIndex;

    return (
        <Flex direction="column" flexGrow={1}>
            <PageHeader title="Animals" />
            <Tabs onChange={(index: number) => { window.location.hash = `#${tabs[index]}`}} defaultIndex={startingTab}>
                <TabList>
                    <Tab>Rare{isSmallDevice ? '' : ' Animals'}</Tab>
                    <Tab>Common{isSmallDevice ? '' : ' Animals'}</Tab>
                    <Tab>{isSmallDevice ? '' : 'Your '}Collection ({getCollectionCount()})</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel px={isSmallDevice ? 0 : 4}>
                        <RareAnimals />
                    </TabPanel>
                    <TabPanel px={isSmallDevice ? 0 : 4}>
                        <CommonAnimals />
                    </TabPanel>
                    <TabPanel px={isSmallDevice ? 0 : 4}>
                        <AnimalCollection />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Flex>
    );
};

export default AnimalTracker;