import { Th, Thead, Tr } from '@chakra-ui/react';
import React from 'react';

const DesktopCommonAnimalHeader = () => (
    <Thead>
        <Tr>
            <Th>Animal</Th>
            <Th>Location</Th>
            <Th>Size</Th>
            <Th display="flex" justifyContent="flex-end">Drops</Th>
            <Th />
        </Tr>
    </Thead>
);

export default DesktopCommonAnimalHeader;