import { Flex, FormControl } from '@chakra-ui/react';
import { MultiValue, Select, components as selectComponents } from 'chakra-react-select';
import React, { CSSProperties, memo, useEffect, useRef } from 'react';
import nodes, { INode } from '../../data/nodes';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';

interface MultiselectOption {
    label: string;
    value: string;
}

interface IMaterialsFormProps {
    filterValues: string[];
    setFilterValues: (filterValues: string[]) => void;
}

const MaterialsForm = ({ filterValues, setFilterValues }: IMaterialsFormProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const selectRef = useRef<any>(null);

    useOutsideAlerter(containerRef, () => {
        selectRef.current?.props?.onMenuClose();
    });
    
    const getOptions = () => {
        const options = nodes.reduce((prev: string[], item: INode) => {
            const newOptions: string[] = [];

            item.drops.forEach(({ name }) => {
                if (!prev.includes(name) && !newOptions.includes(name)) {
                    newOptions.push(name);
                }
            });

            return [...prev, ...newOptions];
        }, []);

        return options;
    };

    const onChangeFilter = (newValues: MultiValue<{ label: string; value: string; }>) => {
        const selections = newValues.map((v: MultiselectOption) => v.value);
        setFilterValues(selections);
        localStorage.setItem('gatheringToolResources', JSON.stringify(selections));
    };

    useEffect(() => {
        // Attempt to restore filters from local storage
        const stored = localStorage.getItem('gatheringToolResources');
        if (stored !== null) {
            setFilterValues(JSON.parse(stored));
        }
    }, [setFilterValues]);

    const onClickValueContainer = () => {
        selectRef.current?.props?.onMenuOpen();
    };
    
    return (
        <Flex>
            <FormControl mb={4} ref={containerRef} backgroundColor="chakra-body-bg" borderRadius="md">
                <Select
                    ref={selectRef}
                    isMulti
                    name="materialsToView"
                    options={getOptions().sort().map((o: string) => ({ label: o, value: o }))}
                    value={filterValues.map((o: string) => ({ label: o, value: o }))}
                    selectedOptionStyle="check"
                    hideSelectedOptions={false}
                    placeholder="Select materials to view"
                    closeMenuOnSelect={false}
                    onChange={onChangeFilter}
                    components={{
                        ValueContainer: ({ children, ...props }) => {
                            const { getValue } = props;

                            return (
                                <selectComponents.ValueContainer {...props}>
                                    <Flex onClick={onClickValueContainer} style={{ width: '100%' } as CSSProperties}>{getValue().length ? `Showing ${getValue().length} of ${getOptions().length}` : 'Select materials to view'}</Flex>
                                </selectComponents.ValueContainer>
                            );
                        },
                        Placeholder: ({ children, ...props }) => {
                            return (
                                <selectComponents.Placeholder {...props}>
                                    {children}
                                </selectComponents.Placeholder>
                            );
                        },
                        MultiValueContainer: () => {
                            return null;
                        },
                        Input: () => {
                            return null;
                        },
                    }}
                />
            </FormControl>
        </Flex>
    );
};

export default memo(MaterialsForm);