import React, { createContext, useEffect, useRef, useState } from 'react';
import { getEorzeaTime } from '../helpers/time';
import { getWeatherChance, getWeatherFromChance, Weather } from '../helpers/weather';

export interface ITime {
    minute: number;
    hour: number;
}

export interface Forecast {
    hour: number;
    weather: Weather;
}

interface ISanctuaryConditions {
    time?: ITime;
    weather?: Weather;
    forecast?: Forecast[];
}

interface ISanctuaryConditionsProviderProps {
    children: JSX.Element | JSX.Element[];
}

const defaultConditions = {};

export const SanctuaryConditionsContext = createContext<ISanctuaryConditions>(defaultConditions);

const SanctuaryConditionsProvider = ({ children }: ISanctuaryConditionsProviderProps) => {
    const conditionsRef = useRef<ISanctuaryConditions>();
    const intervalRef = useRef<number>();
    const [conditions, setConditions] = useState<ISanctuaryConditions>(defaultConditions);

    const updateConditions = () => {
        const time = getEorzeaTime();

        let weather;
        let forecast;

        if (conditionsRef.current && conditionsRef.current.time?.hour === time.hour) {
            weather = conditionsRef.current.weather;
            forecast = conditionsRef.current.forecast;
        } else {
            const weatherChance = getWeatherChance(new Date());
            weather = getWeatherFromChance(weatherChance);

            // Generate the forecast
            // TODO - We don't really need to repeat the logic for each hour
            // since it only changes every 8
            forecast = [{ hour: time.hour, weather }];
            for (let i = 1; i < 24; i++) {
                const hour = time.hour + i < 24 ? time.hour + i : time.hour + i - 24;

                const date = new Date();
                date.setSeconds(date.getSeconds() + (175 * i));

                forecast.push({
                    hour,
                    weather: getWeatherFromChance(getWeatherChance(date)),
                });
            }
        }

        setConditions({ time, weather, forecast });
    };

    useEffect(() => {
        updateConditions();

        intervalRef.current = window.setInterval(() => {
            updateConditions();
        }, 1000);

        return () => { clearInterval(intervalRef.current); };
    }, []);

    useEffect(() => {
        conditionsRef.current = conditions;
    }, [conditions]);

    return (
        <SanctuaryConditionsContext.Provider value={conditions}>
            {children}
        </SanctuaryConditionsContext.Provider>
    );
};

export default SanctuaryConditionsProvider;