import { HStack, Link, Td, Tr, Image } from '@chakra-ui/react';
import React from 'react';
import { IAnimal } from '../../../data/animals';

interface IMobileCommonAnimalRowProps {
    animal: IAnimal;
    openAnimalModal: (animal: IAnimal) => void;
}

const MobileCommonAnimalRow = ({ animal, openAnimalModal }: IMobileCommonAnimalRowProps) => {
    const { name, icon, location, size } = animal;
    return (
        <Tr fontSize='sm'>
            <Td>
                <Link textDecoration="underline" onClick={() => openAnimalModal(animal)}>
                    <HStack>{icon && <Image src={icon} boxSize="8" borderRadius="full" />}<span>{name}</span></HStack>
                </Link>
            </Td>
            <Td><Link onClick={() => openAnimalModal(animal)}>x{location.x}, y{location.y}</Link></Td>
            <Td>{size}</Td>
        </Tr>
    );
};

export default MobileCommonAnimalRow;