import React, { useContext } from 'react';
import { Flex, Text, Heading, useColorModeValue, HStack, VStack, Image } from '@chakra-ui/react';
import { CollectionContext, IDropItem } from '../../../contexts/CollectionContext';
import materials, { IMaterial } from '../../../data/materials';

interface IDropItemProps {
    materialName: string;
    count: number;
}

const DropItem = ({ materialName, count }: IDropItemProps) => {
    const material = materials.find((m: IMaterial) => m.name === materialName);

    if (!material) return null;

    return (
        <HStack>
            <Image src={material.icon} boxSize={6} borderRadius="sm" />
            <Text>{count}x {material.name}</Text>
        </HStack>
    );
};

const DropsTable = () => {
    const { getDropsAssessment } = useContext(CollectionContext);
    const drops = getDropsAssessment();
    return (
        <Flex grow={1} bg={useColorModeValue('gray.100', 'gray.900')} borderRadius="md" p={4} direction="column">
            <Heading as="h3" size="md" mb={2}>Leavings</Heading>

            <HStack alignItems="flex-start">
                <Flex grow={1} backgroundColor="chakra-body-bg" borderRadius="md" p={2} flexDirection="column">
                    <Heading as="h4" size="sm">Common</Heading>
                    <VStack alignItems="flex-start" pt={2}>
                        {drops.common.sort((a: IDropItem, b: IDropItem) => a.material > b.material ? 1 : -1).map((d: IDropItem) => <DropItem key={d.material} materialName={d.material} count={d.count} />)}
                    </VStack>
                </Flex>
                <Flex grow={1} backgroundColor="chakra-body-bg" borderRadius="md" p={2} flexDirection="column">
                    <Heading as="h4" size="sm">Rare</Heading>
                    <VStack alignItems="flex-start" pt={2}>
                        {drops.rare.sort((a: IDropItem, b: IDropItem) => a.material > b.material ? 1 : -1).map((d: IDropItem) => <DropItem key={d.material} materialName={d.material} count={d.count} />)}
                    </VStack>
                </Flex>
            </HStack>
        </Flex>
    );
};

export default DropsTable;