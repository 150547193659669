import { useMediaQuery, Flex, VStack, Box } from '@chakra-ui/react'
import React, { useContext } from 'react';
import { CollectionContext } from '../../../contexts/CollectionContext';
import AddAnimalForm from './AddAnimalForm';
import AnimalsPanel from './AnimalsPanel';
import DropsTable from './DropsTable';

const AnimalCollection = () => {
    const [isSmallDevice] = useMediaQuery('(max-width: 768px)');
    const { collection } = useContext(CollectionContext);

    return (
        <Flex direction={isSmallDevice ? 'column' : 'row'} grow={1}>
            <VStack alignItems="flex-start" flexGrow={1}>
                <AddAnimalForm />
                <Flex grow={1} width="100%">
                    <AnimalsPanel />
                </Flex>
            </VStack>
            {collection.length ? <Box width={isSmallDevice ? '100%' : '40%'} ml={isSmallDevice ? 0 : 4}>
                <DropsTable />
            </Box> : null}
        </Flex>
    )
};

export default AnimalCollection;
