import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { Image } from '@chakra-ui/react';

const SCALE = 1.35;

interface ICoordinate {
    x: number;
    y: number;
}

interface IMarkerProps {
    coordinate: ICoordinate;
    unitOffset: number;
    size?: number;
    color?: string;
}

export enum MarkerColor {
    Red = 'red',
    Cyan = 'cyan',
    White = 'white',
    Yellow = 'yellow',
    Orange = 'orange',
    Purple = 'purple',
    Blue = 'blue',
    Black = 'black',
    Pink = 'pink',
    Green = 'green',
}

export interface IMarker {
    coordinate: ICoordinate,
    color?: string;
    size?: number;
}

interface ISanctuaryMapProps {
    markers?: IMarker[];
}

const Marker = ({ coordinate, unitOffset, size = 1.35, color = 'red' }: IMarkerProps) => {
    const { x, y } = coordinate;
    size = unitOffset * size;

    return <Box
        position="absolute"
        backgroundColor={color}
        border="1px"
        borderColor="gray.900"
        width={size}
        height={size}
        marginLeft={`${unitOffset * x - (size / 2)}px`}
        marginTop={`${unitOffset * y - (size / 2)}px`}
        borderRadius="full"
    />;
};

const SanctuaryMap = ({ markers = [] }: ISanctuaryMapProps) => {
    const outerContainerRef = useRef<HTMLDivElement>(null);
    const [innerContainerSize, setInnerContainerSize] = useState(0);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
            const size = entries[0].contentRect.width;
            setInnerContainerSize(size * SCALE);
        });

        if (outerContainerRef.current) {
            resizeObserver.observe(outerContainerRef.current);
        }
    }, []);

    const overflow = innerContainerSize / SCALE - innerContainerSize;

    return (
        <Flex ref={outerContainerRef} overflow="hidden" flexDirection="column" height={innerContainerSize / SCALE}>
            <Box display="inline-block">
                <Box
                    width={innerContainerSize}
                    height={innerContainerSize}
                    maxHeight={innerContainerSize}
                    overflow="hidden"
                    marginLeft={overflow / 2}
                    marginTop={overflow / 2}
                    bg={useColorModeValue('gray.100', 'gray.900')}
                    borderRadius="md"
                >
                    {!!innerContainerSize && markers.map((c: IMarker, i: number) => <Marker key={i} coordinate={c.coordinate} unitOffset={innerContainerSize / 42} size={c.size} color={c.color} />)}
                    <Image src="https://ffxiv-sanctuary.s3.amazonaws.com/maps/UnnamedIsland.png" maxWidth={innerContainerSize} borderRadius="md" />
                </Box>
            </Box>
        </Flex>
    );
};

export default SanctuaryMap;