import React, { useEffect, useState } from 'react';
import { Box, Container, Flex, useMediaQuery } from '@chakra-ui/react';
import Navbar from './Navbar/Navbar';
import Router from '../router';
import Sidebar from './Sidebar';

const Main = () => {
    const [hasAppMounted, setHasAppMounted] = useState(false);
    const [isMaxWidth] = useMediaQuery('(min-width: 1280px)');
    const [isSmallDevice] = useMediaQuery('(max-width: 768px)');

    // Prevent some flickering in child components
    useEffect(() => {
        setHasAppMounted(true);
    }, [])
    if (!hasAppMounted) return null;

    return (
        <Container height="100vh" maxW='container.xl' padding={0} display="flex" flexDirection="column">
            <Flex marginTop={isMaxWidth ? '4' : 0}>
                <Navbar />
            </Flex>

            <Flex pt={2} pb={2} px={isSmallDevice ? 2 : 0}>
                <Flex flex={3} paddingRight={isSmallDevice ? 0 : 4} alignItems="flex-start"><Router /></Flex>
                {!isSmallDevice && <Flex flex={1} flexDirection="column"><Sidebar /></Flex>}
            </Flex>

            <Flex>{/* Footer */}</Flex>
        </Container>
    );
};

export default Main;
