import React, { createContext, useEffect, useState } from 'react';
import { IAnimal } from '../data/animals';


interface ITracking {
    tracking: IAnimal[];
    addToTracking: (animal: IAnimal) => void;
    removeFromTracking: (name: string) => void;
}

interface ITrackingProviderProps {
    children: JSX.Element | JSX.Element[];
}

export const TrackingContext = createContext<ITracking>({
    tracking: [],
    addToTracking: () => {},
    removeFromTracking: () => {}
});

const TrackingProvider = ({ children }: ITrackingProviderProps) => {
    const [tracking, setTracking] = useState<IAnimal[]>([]);

    useEffect(() => {
        const stored = localStorage.getItem('tracking');
        if (!!stored) {
            setTracking(JSON.parse(stored));
        }
    }, [])

    const addToTracking = (animal: IAnimal) => {
        setTracking((tracking: IAnimal[]) => {
            const newValue = [...tracking, animal];
            localStorage.setItem('tracking', JSON.stringify(newValue));
            return newValue;
        });
    }

    const removeFromTracking = (name: string) => {
        setTracking((tracking: IAnimal[]) => {
            const newValue = tracking.filter((a: IAnimal) => a.name !== name)
            localStorage.setItem('tracking', JSON.stringify(newValue));
            return newValue;
        });
    }

    return (
        <TrackingContext.Provider value={{ tracking, addToTracking, removeFromTracking }}>
            {children}
        </TrackingContext.Provider>
    );
};

export default TrackingProvider;