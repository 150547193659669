import { Td, Tr, Text, Image, VStack, IconButton, useColorModeValue, Link } from '@chakra-ui/react';
import React, { useContext, useEffect, useRef } from 'react';
import { FaTimes } from 'react-icons/fa';
import { intervalToDuration } from 'date-fns/esm';
import { IRareAnimal } from '../../contexts/RareAnimalsContext';
import { TrackingContext } from '../../contexts/TrackingContext';
import { AnimalInfoModalContext } from '../../contexts/AnimalInfoModalContext';
import { getIsSoon } from '../../helpers/time';
import useAnimated, { Animations, Curves } from '../../hooks/useAnimated';
import TrackingRowAnimalName from './TrackingRowAnimalName';

interface ITrackingRowProps {
    isFirstItem: boolean;
    isLastItem: boolean;
    trackingItem: IRareAnimal;
    nextEventTime: Date;
    isActive: boolean;
    hasAlarm?: boolean;
}

const TrackingRow = ({ isFirstItem, isLastItem, trackingItem, isActive, nextEventTime, hasAlarm }: ITrackingRowProps) => {
    const { showAnimalInfoModal } = useContext(AnimalInfoModalContext);
    const { removeFromTracking } = useContext(TrackingContext);
    const { animal, animal: { name, icon }, forecasts } = trackingItem;
    const { renderAnimated, runAnimation } = useAnimated();
    const lastHadAlarmRef = useRef<boolean>(false);

    const forecast = forecasts[0];
    const { startsAt, endsAt } = forecast;

    const highlightColor = useColorModeValue('green.100', 'green.600');
    const warningColor = useColorModeValue('yellow.100', 'yellow.600');

    const onRemove = () => {
        removeFromTracking(name);
    };

    const formatCountdown = (remaining: Duration) => {
        const { days, hours, minutes, seconds } = remaining;

        let output = days ? days + 'd' : '';
        return `${output} ${hours?.toString().padStart(2, '0')}:${minutes?.toString().padStart(2, '0')}:${seconds?.toString().padStart(2, '0')}`;
    };

    const getBackgroundColor = () => {
        if (isActive) {
            return highlightColor;
        } else if (getIsSoon(nextEventTime)) {
            return warningColor;
        }
    };

    const renderTimers = () => {
        if (!startsAt || !endsAt) return null;

        const isCurrentlyActive = new Date() >= startsAt && new Date() < endsAt;
        if (isCurrentlyActive) {
            // Get the time until the animal leaves
            const duration = intervalToDuration({ start: new Date(), end: endsAt });
            return <Text fontSize="sm">Leaves in {formatCountdown(duration)}</Text>;
        } else {
            // Get the time until the animal arrives
            const duration = intervalToDuration({ start: new Date(), end: startsAt });
            return <Text fontSize="sm">Arrives in {formatCountdown(duration)}</Text>;
        }
    };

    useEffect(() => {
        if (hasAlarm && !lastHadAlarmRef.current) {
            runAnimation({
                curve: Curves.EaseInOut,
                duration: 0.5,
                name: Animations.PulsateFwd,
                iterations: 3,
            });
        }

        lastHadAlarmRef.current = hasAlarm || false;
    }, [hasAlarm, runAnimation]);

    const onShowAnimalInfoModal = () => {
        showAnimalInfoModal(animal);
    };

    return (
        <Tr backgroundColor={getBackgroundColor()}>
            <Td p={2} borderTopLeftRadius={isFirstItem ? 'md' : 'none'} borderBottomLeftRadius={isLastItem ? 'md' : 'none'} border={isLastItem ? 'none' : 'unassigned'}>
                <Link onClick={onShowAnimalInfoModal}><Image src={icon} boxSize="8" borderRadius="full" /></Link>
            </Td>
            <Td p={2} border={isLastItem ? 'none' : 'unassigned'}>
                <VStack align="left" spacing={1}>
                    <Link onClick={onShowAnimalInfoModal}><TrackingRowAnimalName name={animal.name} renderAnimated={renderAnimated} /></Link>
                    {renderTimers()}
                </VStack>
            </Td >
            <Td p={2} borderTopRightRadius={isFirstItem ? 'md' : 'none'} borderBottomRightRadius={isLastItem ? 'md' : 'none'} border={isLastItem ? 'none' : 'unassigned'}>
                <IconButton icon={<FaTimes />} aria-label="Stop Tracking" variant="outline" size="xs" onClick={onRemove} />
            </Td>
        </Tr>
    );
};

export default TrackingRow;
