import { Icon } from '@chakra-ui/react';
import { WiCloudy, WiRain, WiDaySunny, WiDaySunnyOvercast, WiShowers, WiFog } from 'react-icons/wi';
import { IEorzeaTime } from './time';

export enum Weather {
    Fair = 'Fair Skies',
    Clear = 'Clear Skies',
    Rain = 'Rain',
    Fog = 'Fog',
    Showers = 'Showers',
    Clouds = 'Clouds',
}

export interface IPreviousWeather {
    previousWeather: Weather;
    endedAtLocal: Date;
    endedAtEorzea: IEorzeaTime;
}

export const getWeatherChance = (time: Date) => {
    const secs = time.getTime() / 1000;

    let eorzeaH = secs / 175;
    let eorzeaJ = secs / 4200;

    eorzeaJ = (eorzeaJ << 32) >>> 0;
    let increment = (eorzeaH + 8 - (eorzeaH % 8)) % 24;
    let seed = eorzeaJ * 100 + increment;

    let s1 = ((seed << 11) ^ seed) >>> 0;
    let s2 = ((s1 >>> 8) ^ s1) >>> 0;

    return s2 % 100;
};

export const getWeatherFromChance = (weatherChance: number) => {
    if (weatherChance < 25) {
        return Weather.Clear;
    } else if (weatherChance < 70) {
        return Weather.Fair;
    } else if (weatherChance < 80) {
        return Weather.Clouds;
    } else if (weatherChance < 90) {
        return Weather.Rain;
    } else if (weatherChance < 95) {
        return Weather.Fog;
    } else {
        return Weather.Showers;
    }
};

export const getWeatherImage = (weather: Weather) => {
    switch (weather) {
        case Weather.Rain: 
            return 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/weather/Rain.png';
        case Weather.Fair:
            return 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/weather/Fair.png';
        case Weather.Clear:
            return 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/weather/Clear.png';
        case Weather.Clouds:
            return 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/weather/Clouds.png';
        case Weather.Fog:
            return 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/weather/Fog.png';
        case Weather.Showers:
            return 'https://ffxiv-sanctuary.s3.amazonaws.com/icons/weather/Showers.png';
    }
}

export const getWeatherIcon = (weather: Weather, size: number) => {
    if (weather === Weather.Rain) {
        return <Icon as={WiRain} w={size} h={size} />;
    } else if (weather === Weather.Fair) {
        return <Icon as={WiDaySunnyOvercast} w={size} h={size} />;
    } else if (weather === Weather.Clear) {
        return <Icon as={WiDaySunny} w={size} h={size} />;
    } else if (weather === Weather.Clouds) {
        return <Icon as={WiCloudy} w={size} h={size} />;
    } else if (weather === Weather.Fog) {
        return <Icon as={WiFog} w={size} h={size} />;
    } else if (weather === Weather.Showers) {
        return <Icon as={WiShowers} w={size} h={size} />;
    }

    return null;
};