import { HStack, Image } from '@chakra-ui/react';
import React from 'react';
import { IAnimalDrop } from '../../data/animals';
import materials, { IMaterial } from '../../data/materials';

interface IAnimalDropProps {
    drop: IAnimalDrop;
}

const AnimalDrop = ({ drop: { name, isRare } }: IAnimalDropProps) => {
    const getIcon = () => {
        const material = materials.find((m: IMaterial) => m.name === name);
        return material?.icon;
    };

    return (
        getIcon() ? <HStack><Image src={getIcon()} boxSize="6" /><span>{name}{isRare ? ' (Rare)' : ''}</span></HStack> : <span>{name}{isRare ? ' (Rare)' : ''}</span>
    );
};

export default AnimalDrop;