import { Th, Thead, Tr } from '@chakra-ui/react';
import React from 'react';

const DesktopNextUpHeader = () => (
    <Thead>
        <Tr>
            <Th>Animal</Th>
            <Th>Available (LT)</Th>
            <Th>Available (ET)</Th>
            <Th>Location</Th>
            <Th>Weather</Th>
            <Th />
            <Th />
        </Tr>
    </Thead>
);

export default DesktopNextUpHeader;