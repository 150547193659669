export interface ICoordinate {
    x: number;
    y: number;
}

export enum NodeDropType {
    Material,
    Seed,
}

export interface INodeDrop {
    name: string;
    type: NodeDropType;
}

export interface INode {
    name: string;
    locations: ICoordinate[];
    drops: INodeDrop[];
}

export default [
    {
        "name": "Agave Plant",
        "drops": [
            { name: "Islewort", type: NodeDropType.Material },
            { name: "Island Hemp", type: NodeDropType.Material },
        ],
        "locations": [
            {
                "x": 10.85,
                "y": 20.6
            },
            {
                "x": 13.75,
                "y": 15.3
            },
            {
                "x": 13.95,
                "y": 13
            },
            {
                "x": 15.2,
                "y": 12.8
            },
            {
                "x": 16,
                "y": 11.3
            },
            {
                "x": 16,
                "y": 21.55
            },
            {
                "x": 17.35,
                "y": 22.15
            },
            {
                "x": 17.85,
                "y": 13.15
            },
            {
                "x": 18.75,
                "y": 13.65
            },
            {
                "x": 19.15,
                "y": 17.45
            },
            {
                "x": 19.5,
                "y": 10.35
            },
            {
                "x": 19.6,
                "y": 10.35
            },
            {
                "x": 19.8,
                "y": 11.6
            },
            {
                "x": 19.8,
                "y": 21.1
            },
            {
                "x": 24.9,
                "y": 29
            },
            {
                "x": 25.55,
                "y": 20.6
            },
            {
                "x": 27.3,
                "y": 29.75
            },
            {
                "x": 27.8,
                "y": 29.85
            },
            {
                "x": 28,
                "y": 23.8
            },
            {
                "x": 28,
                "y": 24.3
            },
            {
                "x": 28.3,
                "y": 23.8
            },
            {
                "x": 28.3,
                "y": 25
            },
            {
                "x": 28.4,
                "y": 10.65
            },
            {
                "x": 28.4,
                "y": 26.05
            },
            {
                "x": 28.5,
                "y": 23.05
            },
            {
                "x": 28.9,
                "y": 29.45
            },
            {
                "x": 29,
                "y": 22.55
            },
            {
                "x": 29.2,
                "y": 29.85
            },
            {
                "x": 29.45,
                "y": 21.85
            },
            {
                "x": 29.55,
                "y": 12.6
            },
            {
                "x": 29.55,
                "y": 26.05
            },
            {
                "x": 31.15,
                "y": 18.75
            },
            {
                "x": 32.2,
                "y": 19.3
            }
        ]
    },
    {
        "name": "Cotton Plant",
        "drops": [
            { name: "Islewort", type: NodeDropType.Material },
            { name: "Island Cotton Boll", type: NodeDropType.Material },
        ],
        "locations": [
            {
                "x": 11.7,
                "y": 19.5
            },
            {
                "x": 11.9,
                "y": 17.55
            },
            {
                "x": 12.9,
                "y": 13.25
            },
            {
                "x": 13.25,
                "y": 16.9
            },
            {
                "x": 16,
                "y": 17.25
            },
            {
                "x": 16.2,
                "y": 21.95
            },
            {
                "x": 18.45,
                "y": 16.4
            },
            {
                "x": 20.1,
                "y": 9.45
            },
            {
                "x": 23.5,
                "y": 25.75
            },
            {
                "x": 26.45,
                "y": 20.7
            },
            {
                "x": 26.55,
                "y": 12.1
            },
            {
                "x": 26.85,
                "y": 28.7
            },
            {
                "x": 26.95,
                "y": 28.9
            },
            {
                "x": 27.05,
                "y": 28.7
            },
            {
                "x": 27.05,
                "y": 28.8
            },
            {
                "x": 27.15,
                "y": 28.9
            },
            {
                "x": 27.3,
                "y": 28.8
            },
            {
                "x": 27.4,
                "y": 28.9
            },
            {
                "x": 30.65,
                "y": 20.9
            },
            {
                "x": 33.55,
                "y": 18.25
            },
            {
                "x": 12.9,
                "y": 21.45
            },
            {
                "x": 13.25,
                "y": 21.55
            },
            {
                "x": 13,
                "y": 21.95
            },
            {
                "x": 12.8,
                "y": 22.45
            },
            {
                "x": 12.7,
                "y": 22.45
            },
            {
                "x": 12.6,
                "y": 22.15
            },
            {
                "x": 12.2,
                "y": 21.75
            },
            {
                "x": 12,
                "y": 21.65
            },
            {
                "x": 11.9,
                "y": 21.35
            },
            {
                "x": 12.3,
                "y": 21.35
            }
        ]
    },
    {
        "name": "Lightly Gnawed Pumpkin",
        "drops": [
            { name: "Island Pumpkin Seeds", type: NodeDropType.Seed },
        ],
        "locations": [
            {
                "x": 17.95,
                "y": 25.95
            },
            {
                "x": 18.05,
                "y": 25.95
            },
            {
                "x": 18.05,
                "y": 26.05
            },
            {
                "x": 18.15,
                "y": 25.95
            },
            {
                "x": 18.15,
                "y": 26.05
            },
            {
                "x": 18.85,
                "y": 25.95
            },
            {
                "x": 18.95,
                "y": 25.85
            },
            {
                "x": 19.05,
                "y": 25.75
            },
            {
                "x": 19.15,
                "y": 25.85
            },
            {
                "x": 19.5,
                "y": 25.75
            }
        ]
    },
    {
        "name": "Partially Consumed Cabbage",
        "drops": [
            { name: "Island Cabbage Seeds", type: NodeDropType.Seed },
        ],
        "locations": [
            {
                "x": 14.95,
                "y": 19.4
            },
            {
                "x": 16.8,
                "y": 19.5
            },
            {
                "x": 16.8,
                "y": 19.6
            },
            {
                "x": 16.8,
                "y": 19.7
            },
            {
                "x": 16.8,
                "y": 19.8
            },
            {
                "x": 16.9,
                "y": 19.9
            }
        ]
    },
    {
        "name": "Sugarcane",
        "drops": [
            { name: 'Island Vine', type: NodeDropType.Material },
            { name: 'Island Sugarcane', type: NodeDropType.Material },
        ],
        "locations": [
            {
                "x": 16.6,
                "y": 16.9
            },
            {
                "x": 16.6,
                "y": 17.35
            },
            {
                "x": 16.6,
                "y": 17.35
            },
            {
                "x": 16.7,
                "y": 16.8
            },
            {
                "x": 16.7,
                "y": 17.25
            },
            {
                "x": 17,
                "y": 23.9
            },
            {
                "x": 17.45,
                "y": 17
            },
            {
                "x": 17.45,
                "y": 17.35
            },
            {
                "x": 17.55,
                "y": 16.7
            },
            {
                "x": 17.55,
                "y": 17.25
            },
            {
                "x": 17.65,
                "y": 16.8
            },
            {
                "x": 17.85,
                "y": 16.3
            },
            {
                "x": 17.85,
                "y": 16.4
            },
            {
                "x": 19.8,
                "y": 25.45
            },
            {
                "x": 20.7,
                "y": 25.55
            },
            {
                "x": 22.75,
                "y": 25.2
            },
            {
                "x": 25.45,
                "y": 26.55
            }
        ]
    },
    {
        "name": "Wild Parsnip",
        "drops": [
            { name: "Islewort", type: NodeDropType.Material },
            { name: "Island Parsnip Seeds", type: NodeDropType.Seed },
        ],
        "locations": [
            {
                "x": 11.8,
                "y": 14.65
            },
            {
                "x": 12.1,
                "y": 14.35
            },
            {
                "x": 12.2,
                "y": 15.05
            },
            {
                "x": 12.3,
                "y": 14.15
            },
            {
                "x": 12.5,
                "y": 13.25
            },
            {
                "x": 12.7,
                "y": 14.85
            },
            {
                "x": 13.35,
                "y": 14.65
            },
            {
                "x": 14.05,
                "y": 16.8
            },
            {
                "x": 14.05,
                "y": 19.5
            },
            {
                "x": 18.15,
                "y": 18.05
            },
            {
                "x": 19.6,
                "y": 22.75
            },
            {
                "x": 21.25,
                "y": 22.85
            },
            {
                "x": 22.05,
                "y": 21.45
            },
            {
                "x": 22.45,
                "y": 23.15
            },
            {
                "x": 25.75,
                "y": 29.85
            },
            {
                "x": 25.95,
                "y": 23.9
            },
            {
                "x": 25.95,
                "y": 30.45
            },
            {
                "x": 26.05,
                "y": 23.15
            },
            {
                "x": 26.35,
                "y": 23.15
            },
            {
                "x": 26.55,
                "y": 24
            },
            {
                "x": 27.15,
                "y": 22.95
            },
            {
                "x": 27.4,
                "y": 22.85
            },
            {
                "x": 27.7,
                "y": 22.45
            },
            {
                "x": 28.8,
                "y": 28.4
            },
            {
                "x": 30.05,
                "y": 28.3
            }
        ]
    },
    {
        "name": "Wild Popoto (Island)",
        "drops": [
            { name: "Islewort", type: NodeDropType.Material },
            { name: "Island Popoto Set", type: NodeDropType.Seed },
        ],
        "locations": [
            {
                "x": 9.85,
                "y": 20.4
            },
            {
                "x": 11.9,
                "y": 11.6
            },
            {
                "x": 12.2,
                "y": 11.05
            },
            {
                "x": 12.2,
                "y": 12.3
            },
            {
                "x": 12.3,
                "y": 11.6
            },
            {
                "x": 12.4,
                "y": 11.9
            },
            {
                "x": 12.5,
                "y": 10.75
            },
            {
                "x": 12.5,
                "y": 11.4
            },
            {
                "x": 14.35,
                "y": 20
            },
            {
                "x": 15.9,
                "y": 22.65
            },
            {
                "x": 21.25,
                "y": 22.35
            },
            {
                "x": 22.65,
                "y": 23.5
            },
            {
                "x": 23.9,
                "y": 25.85
            },
            {
                "x": 24.1,
                "y": 25.45
            },
            {
                "x": 24.5,
                "y": 25.85
            },
            {
                "x": 24.5,
                "y": 26.55
            },
            {
                "x": 25.1,
                "y": 24.8
            },
            {
                "x": 25.2,
                "y": 24.2
            },
            {
                "x": 25.45,
                "y": 25.65
            },
            {
                "x": 25.55,
                "y": 24.8
            }
        ]
    },
    {
        "name": "Island Apple Tree",
        "drops": [
            { name: 'Island Apple', type: NodeDropType.Material },
            { name: 'Island Vine', type: NodeDropType.Material },
            { name: 'Island Beehive Chip', type: NodeDropType.Material },
        ],
        "locations": [
            {
                "x": 17.45,
                "y": 26.35
            },
            {
                "x": 18.25,
                "y": 12.1
            },
            {
                "x": 18.85,
                "y": 26.35
            },
            {
                "x": 19.05,
                "y": 24.2
            },
            {
                "x": 19.5,
                "y": 15.05
            },
            {
                "x": 19.7,
                "y": 11.05
            },
            {
                "x": 19.7,
                "y": 20
            },
            {
                "x": 19.7,
                "y": 26.45
            },
            {
                "x": 19.8,
                "y": 24.5
            },
            {
                "x": 19.9,
                "y": 19.4
            },
            {
                "x": 20.2,
                "y": 24.9
            },
            {
                "x": 20.9,
                "y": 24.9
            },
            {
                "x": 21.45,
                "y": 20.4
            },
            {
                "x": 22.95,
                "y": 21
            },
            {
                "x": 22.95,
                "y": 24.1
            },
            {
                "x": 22.95,
                "y": 26.55
            },
            {
                "x": 23.15,
                "y": 11.9
            },
            {
                "x": 23.3,
                "y": 25
            },
            {
                "x": 25.65,
                "y": 22.45
            },
            {
                "x": 28.2,
                "y": 12.6
            }
        ]
    },
    {
        "name": "Mahogany Tree",
        "drops": [
            { name: 'Island Sap', type: NodeDropType.Material },
            { name: 'Island Log', type: NodeDropType.Material },
            { name: 'Island Wood Opal', type: NodeDropType.Material },
        ],
        "locations": [
            {
                "x": 12.5,
                "y": 15.7
            },
            {
                "x": 13.75,
                "y": 20.2
            },
            {
                "x": 15.3,
                "y": 18.05
            },
            {
                "x": 16.4,
                "y": 19.9
            },
            {
                "x": 17.85,
                "y": 20.8
            },
            {
                "x": 18.45,
                "y": 19.7
            },
            {
                "x": 18.45,
                "y": 21.75
            },
            {
                "x": 18.55,
                "y": 20.7
            },
            {
                "x": 18.85,
                "y": 23.05
            },
            {
                "x": 20,
                "y": 24.1
            },
            {
                "x": 20.7,
                "y": 24.4
            },
            {
                "x": 21.55,
                "y": 23.9
            },
            {
                "x": 21.95,
                "y": 24.6
            },
            {
                "x": 22.15,
                "y": 21.95
            },
            {
                "x": 23.7,
                "y": 10.95
            },
            {
                "x": 25.65,
                "y": 27.15
            },
            {
                "x": 27.4,
                "y": 27.7
            },
            {
                "x": 27.6,
                "y": 12.1
            },
            {
                "x": 28.2,
                "y": 20.5
            },
            {
                "x": 30.35,
                "y": 12.2
            },
            {
                "x": 32.3,
                "y": 15.05
            }
        ]
    },
    {
        "name": "Palm Tree",
        "drops": [
            { name: 'Island Palm Leaf', type: NodeDropType.Material },
            { name: 'Island Palm Log', type: NodeDropType.Material },
            { name: 'Island Coconut', type: NodeDropType.Material },
        ],
        "locations": [
            {
                "x": 13.55,
                "y": 12.6
            },
            {
                "x": 14.65,
                "y": 12.4
            },
            {
                "x": 15.5,
                "y": 12.2
            },
            {
                "x": 16.3,
                "y": 12.4
            },
            {
                "x": 16.6,
                "y": 13.15
            },
            {
                "x": 16.8,
                "y": 23.15
            },
            {
                "x": 16.9,
                "y": 22.45
            },
            {
                "x": 17.1,
                "y": 21.75
            },
            {
                "x": 17.35,
                "y": 21.55
            },
            {
                "x": 17.55,
                "y": 22.35
            },
            {
                "x": 17.55,
                "y": 25.85
            },
            {
                "x": 17.75,
                "y": 25.2
            },
            {
                "x": 18.35,
                "y": 22.35
            },
            {
                "x": 18.45,
                "y": 23.05
            },
            {
                "x": 18.85,
                "y": 25.2
            },
            {
                "x": 27.15,
                "y": 30.25
            },
            {
                "x": 27.6,
                "y": 24.8
            },
            {
                "x": 28.4,
                "y": 25
            },
            {
                "x": 28.5,
                "y": 23.4
            },
            {
                "x": 28.8,
                "y": 25.95
            },
            {
                "x": 28.9,
                "y": 12.3
            },
            {
                "x": 29.2,
                "y": 21.65
            },
            {
                "x": 29.65,
                "y": 27.3
            },
            {
                "x": 31.5,
                "y": 19.8
            },
            {
                "x": 33.45,
                "y": 17.95
            }
        ]
    },
    {
        "name": "Tualong Tree",
        "drops": [
            { name: 'Island Branch', type: NodeDropType.Material },
            { name: 'Island Log', type: NodeDropType.Material },
            { name: 'Island Resin', type: NodeDropType.Material },
        ],
        "locations": [
            {
                "x": 11.7,
                "y": 15.2
            },
            {
                "x": 12.3,
                "y": 16.6
            },
            {
                "x": 13,
                "y": 19.5
            },
            {
                "x": 15.9,
                "y": 21
            },
            {
                "x": 16.1,
                "y": 16
            },
            {
                "x": 18.45,
                "y": 26.65
            },
            {
                "x": 19.15,
                "y": 25.35
            },
            {
                "x": 19.6,
                "y": 18.75
            },
            {
                "x": 19.9,
                "y": 13.15
            },
            {
                "x": 19.9,
                "y": 16
            },
            {
                "x": 20,
                "y": 25.55
            },
            {
                "x": 20.8,
                "y": 18.55
            },
            {
                "x": 20.8,
                "y": 24.8
            },
            {
                "x": 20.9,
                "y": 26.55
            },
            {
                "x": 21.45,
                "y": 25
            },
            {
                "x": 21.45,
                "y": 26.15
            },
            {
                "x": 21.55,
                "y": 11.3
            },
            {
                "x": 22.35,
                "y": 24.9
            },
            {
                "x": 22.55,
                "y": 26.05
            },
            {
                "x": 22.95,
                "y": 9.85
            },
            {
                "x": 23.4,
                "y": 24.8
            },
            {
                "x": 24.1,
                "y": 20.3
            },
            {
                "x": 24.1,
                "y": 22.55
            },
            {
                "x": 24.7,
                "y": 10.55
            },
            {
                "x": 25.2,
                "y": 30.25
            },
            {
                "x": 26.25,
                "y": 11.2
            },
            {
                "x": 27.05,
                "y": 21.95
            },
            {
                "x": 27.6,
                "y": 27.9
            },
            {
                "x": 28.9,
                "y": 10.35
            },
            {
                "x": 29.75,
                "y": 18.55
            },
            {
                "x": 29.95,
                "y": 14.05
            },
            {
                "x": 31.05,
                "y": 14.45
            },
            {
                "x": 32.1,
                "y": 17.65
            },
            {
                "x": 12.5,
                "y": 21.95
            },
            {
                "x": 12.1,
                "y": 21.75
            }
        ]
    },
    {
        "name": "Mound of Dirt",
        "drops": [
            { name: 'Island Sand', type: NodeDropType.Material },
            { name: 'Island Clay', type: NodeDropType.Material },
        ],
        "locations": [
            {
                "x": 14.25,
                "y": 12.7
            },
            {
                "x": 14.55,
                "y": 12.7
            },
            {
                "x": 17.45,
                "y": 17.95
            },
            {
                "x": 17.45,
                "y": 18.05
            },
            {
                "x": 18.55,
                "y": 14.15
            },
            {
                "x": 21.95,
                "y": 25.55
            },
            {
                "x": 22.15,
                "y": 25.65
            },
            {
                "x": 22.25,
                "y": 25.55
            },
            {
                "x": 22.25,
                "y": 25.65
            },
            {
                "x": 22.25,
                "y": 25.65
            },
            {
                "x": 22.45,
                "y": 25.55
            },
            {
                "x": 22.45,
                "y": 25.65
            },
            {
                "x": 24.2,
                "y": 25.95
            },
            {
                "x": 28.4,
                "y": 27.05
            },
            {
                "x": 29,
                "y": 25
            },
            {
                "x": 29.1,
                "y": 23.4
            },
            {
                "x": 29.65,
                "y": 21.75
            }
        ]
    },
    {
        "name": "Submerged Sand",
        "drops": [
            { name: 'Island Sand', type: NodeDropType.Material },
            { name: 'Island Tinsand', type: NodeDropType.Material },
        ],
        "locations": [
            {
                "x": 16.9,
                "y": 18.05
            },
            {
                "x": 17,
                "y": 16.3
            },
            {
                "x": 17.1,
                "y": 13.75
            },
            {
                "x": 17.25,
                "y": 17.1
            },
            {
                "x": 17.35,
                "y": 12.5
            },
            {
                "x": 17.35,
                "y": 14.95
            },
            {
                "x": 17.35,
                "y": 20.4
            },
            {
                "x": 17.45,
                "y": 19.7
            },
            {
                "x": 17.45,
                "y": 21.25
            },
            {
                "x": 17.55,
                "y": 19.9
            },
            {
                "x": 17.55,
                "y": 20.6
            },
            {
                "x": 17.55,
                "y": 21.45
            },
            {
                "x": 18.05,
                "y": 21.95
            },
            {
                "x": 21.25,
                "y": 25.45
            },
            {
                "x": 22.75,
                "y": 25.55
            },
            {
                "x": 25.45,
                "y": 26.25
            },
            {
                "x": 26.95,
                "y": 26.35
            }
        ]
    },
    {
        "name": "Bluish Rock",
        "drops": [
            { name: 'Island Stone', type: NodeDropType.Material },
            { name: 'Island Copper Ore', type: NodeDropType.Material },
        ],
        "locations": [
            {
                "x": 29.65,
                "y": 28.3
            },
            {
                "x": 30.15,
                "y": 29.1
            },
            {
                "x": 30.55,
                "y": 28.3
            },
            {
                "x": 30.95,
                "y": 29
            },
            {
                "x": 31.5,
                "y": 28.1
            },
            {
                "x": 31.15,
                "y": 27.9
            },
            {
                "x": 31.25,
                "y": 27.5
            },
            {
                "x": 28.9,
                "y": 27.4
            },
            {
                "x": 30.95,
                "y": 11.4
            },
            {
                "x": 29.95,
                "y": 10.55
            },
            {
                "x": 28.6,
                "y": 9.55
            },
            {
                "x": 28,
                "y": 9.25
            },
            {
                "x": 23.8,
                "y": 9.35
            },
            {
                "x": 16.8,
                "y": 14.55
            },
            {
                "x": 15.9,
                "y": 14.55
            },
            {
                "x": 16.8,
                "y": 14.75
            },
            {
                "x": 16.7,
                "y": 14.95
            },
            {
                "x": 17,
                "y": 15.8
            },
            {
                "x": 16.3,
                "y": 15.8
            },
            {
                "x": 15.7,
                "y": 14.95
            }
        ]
    },
    {
        "name": "Crystal-banded Rock",
        "drops": [
            { name: 'Island Stone', type: NodeDropType.Material },
            { name: 'Island Rock Salt', type: NodeDropType.Material },
        ],
        "locations": [
            {
                "x": 31.4,
                "y": 20.6
            },
            {
                "x": 31.25,
                "y": 20.6
            },
            {
                "x": 31.25,
                "y": 20.5
            },
            {
                "x": 31.4,
                "y": 20.5
            },
            {
                "x": 31.4,
                "y": 20.4
            },
            {
                "x": 31.5,
                "y": 20.4
            },
            {
                "x": 18.65,
                "y": 11.5
            },
            {
                "x": 18.75,
                "y": 11.5
            },
            {
                "x": 18.75,
                "y": 11.4
            },
            {
                "x": 18.75,
                "y": 11.3
            }
        ]
    },
    {
        "name": "Quartz Formation",
        "drops": [
            { name: 'Island Stone', type: NodeDropType.Material },
            { name: 'Island Quartz', type: NodeDropType.Material },
        ],
        "locations": [
            {
                "x": 26.85,
                "y": 18.75
            },
            {
                "x": 26.35,
                "y": 18.55
            },
            {
                "x": 28.9,
                "y": 17.95
            },
            {
                "x": 27.6,
                "y": 9.55
            },
            {
                "x": 21.75,
                "y": 15.7
            },
            {
                "x": 21.75,
                "y": 15.8
            },
            {
                "x": 21.75,
                "y": 15.9
            },
            {
                "x": 21.75,
                "y": 15.9
            },
            {
                "x": 24.4,
                "y": 15.4
            },
            {
                "x": 24.5,
                "y": 15.5
            },
            {
                "x": 24.7,
                "y": 15.4
            },
            {
                "x": 24.7,
                "y": 15.5
            },
            {
                "x": 24.6,
                "y": 15.7
            },
            {
                "x": 24.6,
                "y": 15.6
            }
        ]
    },
    {
        "name": "Rough Black Rock",
        "drops": [
            { name: 'Island Stone', type: NodeDropType.Material },
            { name: 'Island Iron Ore', type: NodeDropType.Material },
        ],
        "locations": [
            {
                "x": 24,
                "y": 18.95
            },
            {
                "x": 26.75,
                "y": 18.75
            },
            {
                "x": 26.65,
                "y": 18.45
            },
            {
                "x": 26.05,
                "y": 18.75
            },
            {
                "x": 25.55,
                "y": 19.05
            },
            {
                "x": 25.55,
                "y": 19.15
            },
            {
                "x": 25.65,
                "y": 19.05
            },
            {
                "x": 26.25,
                "y": 19.5
            },
            {
                "x": 27.05,
                "y": 19.05
            },
            {
                "x": 28.8,
                "y": 18.15
            },
            {
                "x": 29.1,
                "y": 18.25
            },
            {
                "x": 28.5,
                "y": 19.05
            },
            {
                "x": 29.35,
                "y": 10.15
            },
            {
                "x": 28.6,
                "y": 9.15
            },
            {
                "x": 23.8,
                "y": 11.7
            },
            {
                "x": 28.7,
                "y": 12.9
            },
            {
                "x": 24.6,
                "y": 15.4
            },
            {
                "x": 24.7,
                "y": 15.6
            },
            {
                "x": 24.5,
                "y": 15.7
            }
        ]
    },
    {
        "name": "Smooth White Rock",
        "drops": [
            { name: 'Island Stone', type: NodeDropType.Material },
            { name: 'Island Limestone', type: NodeDropType.Material },
        ],
        "locations": [
            {
                "x": 21.85,
                "y": 25.55
            },
            {
                "x": 28.4,
                "y": 22.15
            },
            {
                "x": 27.3,
                "y": 22.35
            },
            {
                "x": 28.7,
                "y": 21.45
            },
            {
                "x": 28.5,
                "y": 20.9
            },
            {
                "x": 28.5,
                "y": 20.7
            },
            {
                "x": 28.7,
                "y": 19.8
            },
            {
                "x": 23.8,
                "y": 10.35
            },
            {
                "x": 21.45,
                "y": 10.55
            },
            {
                "x": 21.1,
                "y": 10.45
            },
            {
                "x": 25.85,
                "y": 10.15
            },
            {
                "x": 26.75,
                "y": 10.45
            },
            {
                "x": 13.25,
                "y": 12.9
            },
            {
                "x": 13.65,
                "y": 13.65
            },
            {
                "x": 14.35,
                "y": 13.95
            },
            {
                "x": 14.75,
                "y": 13.95
            },
            {
                "x": 16.6,
                "y": 14.55
            },
            {
                "x": 14.25,
                "y": 14.45
            },
            {
                "x": 13.95,
                "y": 14.45
            },
            {
                "x": 17.55,
                "y": 21.65
            },
            {
                "x": 17.75,
                "y": 21.95
            },
            {
                "x": 17.75,
                "y": 22.05
            },
            {
                "x": 17.85,
                "y": 22.15
            },
            {
                "x": 17.85,
                "y": 22.25
            },
            {
                "x": 17.65,
                "y": 22.15
            },
            {
                "x": 17.65,
                "y": 22.15
            },
            {
                "x": 16.2,
                "y": 23.8
            },
            {
                "x": 16.9,
                "y": 23.5
            },
            {
                "x": 16.7,
                "y": 23.9
            }
        ]
    },
    {
        "name": "Speckled Rock",
        "drops": [
            { name: 'Island Stone', type: NodeDropType.Material },
            { name: 'Island Leucogranite', type: NodeDropType.Material },
        ],
        "locations": [
            {
                "x": 23.3,
                "y": 19.15
            },
            {
                "x": 23.8,
                "y": 19.05
            },
            {
                "x": 26.45,
                "y": 19.5
            },
            {
                "x": 27.15,
                "y": 18.95
            },
            {
                "x": 28.5,
                "y": 18.25
            },
            {
                "x": 29.2,
                "y": 18.05
            },
            {
                "x": 28.5,
                "y": 18.85
            },
            {
                "x": 31.9,
                "y": 11.9
            },
            {
                "x": 30.85,
                "y": 11.2
            },
            {
                "x": 31.6,
                "y": 10.55
            },
            {
                "x": 31.15,
                "y": 10.35
            },
            {
                "x": 30.15,
                "y": 10.55
            },
            {
                "x": 29.65,
                "y": 10.15
            },
            {
                "x": 29.1,
                "y": 9.65
            },
            {
                "x": 28.8,
                "y": 8.8
            },
            {
                "x": 27.05,
                "y": 9.25
            },
            {
                "x": 25.95,
                "y": 9.35
            },
            {
                "x": 24.7,
                "y": 15.6
            },
            {
                "x": 24.5,
                "y": 15.6
            }
        ]
    },
    {
        "name": "Coral Formation",
        "drops": [
            { name: 'Island Coral', type: NodeDropType.Material },
            { name: 'Island Jellyfish', type: NodeDropType.Material },
        ],
        "locations": [
            {
                "x": 32.3,
                "y": 26.05
            },
            {
                "x": 32,
                "y": 25.95
            },
            {
                "x": 31.7,
                "y": 25.55
            },
            {
                "x": 31.8,
                "y": 24.6
            },
            {
                "x": 32,
                "y": 24.7
            },
            {
                "x": 32.3,
                "y": 24.6
            },
            {
                "x": 32.6,
                "y": 24.1
            },
            {
                "x": 32.2,
                "y": 23.3
            }
        ]
    },
    {
        "name": "Large Shell",
        "drops": [
            { name: 'Island Clam', type: NodeDropType.Material },
            { name: 'Islefish', type: NodeDropType.Material },
        ],
        "locations": [
            {
                "x": 14.55,
                "y": 9.25
            },
            {
                "x": 14.75,
                "y": 8.9
            },
            {
                "x": 17.1,
                "y": 9.55
            },
            {
                "x": 17.35,
                "y": 9.85
            },
            {
                "x": 17.85,
                "y": 9
            },
            {
                "x": 17.75,
                "y": 10.15
            },
            {
                "x": 18.25,
                "y": 9.75
            },
            {
                "x": 18.15,
                "y": 9.35
            }
        ]
    },
    {
        "name": "Seaweed Tangle",
        "drops": [
            { name: 'Island Laver', type: NodeDropType.Material },
            { name: 'Island Squid', type: NodeDropType.Material },
        ],
        "locations": [
            {
                "x": 32.1,
                "y": 26.55
            },
            {
                "x": 32.5,
                "y": 26.35
            },
            {
                "x": 32.4,
                "y": 25.35
            },
            {
                "x": 32.4,
                "y": 25
            },
            {
                "x": 32.9,
                "y": 23.8
            },
            {
                "x": 32,
                "y": 23.7
            },
            {
                "x": 31.9,
                "y": 23.3
            },
            {
                "x": 32.7,
                "y": 23.05
            },
            {
                "x": 13.15,
                "y": 9.45
            },
            {
                "x": 14.35,
                "y": 9.75
            },
            {
                "x": 14.65,
                "y": 9
            },
            {
                "x": 16.8,
                "y": 9.45
            },
            {
                "x": 17.25,
                "y": 9
            },
            {
                "x": 18.25,
                "y": 9.85
            },
            {
                "x": 18.45,
                "y": 9.25
            }
        ]
    },
    {
        "name": "Multicolored Isleblooms",
        "drops": [
            { name: 'Multicolored Isleblooms', type: NodeDropType.Material },
        ],
        "locations": [
            {
                "x": 21.8,
                "y": 16.2,
            },
            {
                "x": 21.9,
                "y": 29.0,
            },
            {
                "x": 18.0,
                "y": 30.4
            },
            {
                "x": 14.8,
                "y": 22.6,
            },
        ]
    }
] as INode[];