import React, { useContext } from 'react';
import { Link, Td, Tr, useColorModeValue, Image, HStack, Tooltip, Button, useToast, Icon, Box } from '@chakra-ui/react';
import { FaFeather } from 'react-icons/fa';
import format from 'date-fns/format';
import { IAnimalForecastItem } from './NextUpTable';
import { getEorzeaTime } from '../../../helpers/time';
import { IAnimal } from '../../../data/animals';
import { getWeatherImage } from '../../../helpers/weather';
import { TrackingContext } from '../../../contexts/TrackingContext';
import { CollectionContext } from '../../../contexts/CollectionContext';

interface IDesktopNextUpRowProps {
    forecastItem: IAnimalForecastItem;
    onOpenModal: (animal: IAnimal) => void;
}

const DesktopNextUpRow = ({ forecastItem: { animal, forecast }, onOpenModal }: IDesktopNextUpRowProps) => {
    const { name, location, conditions: { weather, requiresFlight }, icon } = animal;
    const toast = useToast();
    // @ts-ignore
    const { startsAt } = forecast;
    const { tracking, addToTracking } = useContext(TrackingContext);
    const { addToCollection } = useContext(CollectionContext);
    const highlightColor = useColorModeValue('green.100', 'green.600');

    const getIsCurrentlyActive = () => {
        return new Date() >= startsAt;
    };

    const getFormattedStartTime = () => {
        return startsAt.getDay() === new Date().getDay() ? format(startsAt, 'HH:mm:ss') : format(startsAt, 'MMM d, HH:mm:ss');
    };

    const renderStartTimeLT = () => {
        return <Td>{getIsCurrentlyActive() ? 'Now' : getFormattedStartTime()}</Td>;
    };

    const onClickTrack = () => {
        addToTracking(animal);
    };

    const onClickCollect = () => {
        addToCollection(animal.name);
        toast({
            title: 'Animal Collected',
            description: `${animal.name} has been added to your collection!`,
            status: 'success',
            duration: 3000,
            isClosable: true,
        });
    };

    return (
        <>
            <Tr backgroundColor={getIsCurrentlyActive() ? highlightColor : 'none'} fontSize='sm'>
                <Td>
                    <HStack spacing={0}>
                        <Link textDecoration="underline" onClick={() => onOpenModal(animal)}>
                            <HStack>
                                {icon && <Image src={icon} boxSize="8" borderRadius="full" />}
                                <span>{name}</span>
                            </HStack>

                        </Link>
                    </HStack>
                </Td>
                {renderStartTimeLT()}
                <Td>{getIsCurrentlyActive() ? 'Now' : String(getEorzeaTime(startsAt).hour).padStart(2, '0') + ':00'}</Td>
                <Td>
                    <Link whiteSpace={'nowrap'} onClick={() => onOpenModal(animal)} display="flex">
                        x{location.x}, y{location.y}
                        {requiresFlight && <Tooltip label="Flight required" bg="gray.900" color="gray.100"><Box ml="1"><Icon as={FaFeather} /></Box></Tooltip>}
                    </Link>
                </Td>
                <Td>{weather && <Tooltip label={weather} bg="gray.900" color="gray.100"><Image src={getWeatherImage(weather)} boxSize="8" /></Tooltip>}</Td>
                <Td>
                    <Button onClick={onClickTrack} size="sm" disabled={!!tracking.find((a: IAnimal) => a.name === animal.name)}>Track</Button>
                </Td>
                <Td>
                    <Button onClick={onClickCollect} size="sm">Collect</Button>
                </Td>
            </Tr>
        </>
    );
};

export default DesktopNextUpRow;
