import React, { createContext, useState } from 'react';
import AnimalInfoModal from '../components/AnimalInfoModal/AnimalInfoModal';
import { IAnimal } from '../data/animals';

interface IAnimalInfoModalContext {
    showAnimalInfoModal: (animal: IAnimal) => void;
    closeAnimalInfoModal: () => void;
}

interface IAnimalInfoModalProviderProps {
    children: JSX.Element | JSX.Element[];
}

export const AnimalInfoModalContext = createContext<IAnimalInfoModalContext>({
    showAnimalInfoModal: () => { },
    closeAnimalInfoModal: () => { },
});

const AnimalInfoModalProvider = ({ children }: IAnimalInfoModalProviderProps) => {
    const [activeAnimal, setActiveAnimal] = useState<IAnimal | undefined>();

    const showAnimalInfoModal = (animal: IAnimal) => { setActiveAnimal(animal); };
    const closeAnimalInfoModal = () => { setActiveAnimal(undefined); };

    return (
        <AnimalInfoModalContext.Provider value={{ showAnimalInfoModal, closeAnimalInfoModal }}>
            {children}
            <AnimalInfoModal animal={activeAnimal} onClose={closeAnimalInfoModal} />
        </AnimalInfoModalContext.Provider>
    );
};

export default AnimalInfoModalProvider;