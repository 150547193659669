import { Flex, Table, useMediaQuery, Text, Tbody } from '@chakra-ui/react';
import React, { useContext } from 'react';
import animals, { IAnimal } from '../../../data/animals';
import MobileCommonAnimalHeader from './MobileCommonAnimalsHeader';
import DesktopCommonAnimalHeader from './DesktopCommonAnimalsHeader';
import DesktopCommonAnimalRow from './DesktopCommonAnimalRow';
import MobileCommonAnimalRow from './MobileCommonAnimalRow';
import { AnimalInfoModalContext } from '../../../contexts/AnimalInfoModalContext';

const CommonAnimals = () => {
    const [isSmallDevice] = useMediaQuery('(max-width: 768px)');
    const { showAnimalInfoModal } = useContext(AnimalInfoModalContext);

    const commonAnimals = animals.filter((a: IAnimal) => !a.conditions).sort((a: IAnimal, b: IAnimal) => a.name > b.name ? 1 : -1);

    return (
        <>
            <Flex grow={1} direction="column">
                <Text fontSize={isSmallDevice ? 'sm' : 'md'}>These animals are available at all times.</Text>
                <Table variant='simple' size="sm" mt={4}>
                    {isSmallDevice ? <MobileCommonAnimalHeader /> : <DesktopCommonAnimalHeader />}

                    <Tbody>
                        {commonAnimals.map((a: IAnimal, i: number) => (
                            isSmallDevice ? <MobileCommonAnimalRow key={i} openAnimalModal={showAnimalInfoModal} animal={a} /> : <DesktopCommonAnimalRow key={i} openAnimalModal={showAnimalInfoModal} animal={a} />))
                        }
                    </Tbody>
                </Table>
            </Flex>
        </>
    );
};

export default CommonAnimals;
