import { MutableRefObject, useEffect } from 'react';

export default function useOutsideAlerter(ref: MutableRefObject<any>, onOutsideAlert: () => void) {
    useEffect(() => {
        function handleClickOutside(event: Event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onOutsideAlert();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, onOutsideAlert]);
}
