import { Box, Button, Flex, Table, Tbody, useMediaQuery } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { AnimalInfoModalContext } from '../../../contexts/AnimalInfoModalContext';
import { IRareAnimal, RareAnimalsContext } from '../../../contexts/RareAnimalsContext';
import { IAnimal } from '../../../data/animals';
import { IAnimalForecast } from '../../../helpers/animals';
import DesktopNextUpHeader from './DesktopNextUpHeader';
import DesktopNextUpRow from './DesktopNextUpRow';
import MobileNextUpHeader from './MobileNextUpHeader';
import MobileNextUpRow from './MobileNextUpRow';
import NextUpFilter from './NextUpFilter';

export interface IAnimalForecastItem {
	animal: IAnimal;
	forecast: IAnimalForecast;
}

const NextUpTable = () => {
	const [forecastItems, setForecastItems] = useState<IAnimalForecastItem[]>([]);
	const [animalFilterValues, setAnimalFilterValues] = useState<string[]>([]);
	const { forecastRange, setForecastRange, rareAnimals } = useContext(RareAnimalsContext);
	const [isSmallDevice] = useMediaQuery('(max-width: 768px)');
	const { showAnimalInfoModal } = useContext(AnimalInfoModalContext);
	
	useEffect(() => {
		const newForecastItems: IAnimalForecastItem[] = [];

		// Flatten the rare animal data
		rareAnimals.forEach((r: IRareAnimal) => {
			r.forecasts.forEach((f: IAnimalForecast) => {
				newForecastItems.push({ animal: r.animal, forecast: f });
			});
		});

		// Secondary sort by name
		const sortedByAnimalName = newForecastItems.sort((a: IAnimalForecastItem, b: IAnimalForecastItem) => a.animal.name < b.animal.name ? 1 : -1);

		// Primary sort by start time
		const sortedByNextAppearance = sortedByAnimalName.sort((a: IAnimalForecastItem, b: IAnimalForecastItem) => {
			if (!a.forecast.startsAt || !b.forecast.startsAt) return -1;
			return a.forecast.startsAt > b.forecast.startsAt ? 1 : -1;
		});

		setForecastItems(sortedByNextAppearance);
	}, [rareAnimals]);

	const onShowMore = () => {
		setForecastRange(forecastRange + 1);
	};

	const onShowLess = () => {
		setForecastRange(forecastRange - 1);
	};

	const visibleForecasts = forecastItems.filter((f: IAnimalForecastItem) => animalFilterValues.includes(f.animal.name));

	return (
		<>
			<Flex direction="column">
				<NextUpFilter filterValues={animalFilterValues} setFilterValues={setAnimalFilterValues} />
				{!!visibleForecasts.length &&
					<>
						<Table variant='simple' size={isSmallDevice ? 'sm' : 'md'}>
							{isSmallDevice ? <MobileNextUpHeader /> : <DesktopNextUpHeader />}

							<Tbody>
								{visibleForecasts.map((f: IAnimalForecastItem, i: number) => (
									isSmallDevice ? <MobileNextUpRow forecastItem={f} onOpenModal={showAnimalInfoModal} key={i} /> :
										<DesktopNextUpRow forecastItem={f} onOpenModal={showAnimalInfoModal} key={i} />
								))}
							</Tbody>
						</Table>
						<Box>
							<Button variant={"ghost"} colorScheme="green" mt={4} size="sm" onClick={onShowMore}>Show More</Button>
							<Button variant={"ghost"} colorScheme="green" mt={4} size="sm" onClick={onShowLess} disabled={forecastRange === 1}>Show Less</Button>
						</Box>
					</>
				}
			</Flex>
		</>
	);
};

export default NextUpTable;
