import React, { useContext } from 'react';
import { Box, Flex, HStack, Text, Image } from '@chakra-ui/react';
import { SanctuaryConditionsContext } from '../../contexts/SanctuaryConditionsContext';
import { formatTwoDigits } from '../../helpers/timeFormat';
import { getWeatherImage } from '../../helpers/weather';

const SanctuaryConditions = () => {
    const { weather, time } = useContext(SanctuaryConditionsContext);
    
    if (!weather || !time) return null;

    const { hour, minute } = time;

    return (
        <Box w={82}>
            <HStack spacing={2} display="flex" justifyContent="space-between">
                <Box><Text>{formatTwoDigits(hour)}:{formatTwoDigits(minute)}</Text></Box>
                <Flex title={weather}><Image src={getWeatherImage(weather)} boxSize="8" /></Flex>
            </HStack>
        </Box>
    )
};

export default SanctuaryConditions;