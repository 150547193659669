import { FormControl, Flex } from '@chakra-ui/react';
import { MultiValue, Select, components as selectComponents } from 'chakra-react-select';
import React, { CSSProperties, memo, useEffect, useRef } from 'react';
import { IAnimal } from '../../../data/animals';
import { getRareAnimals } from '../../../helpers/animals';
import useOutsideAlerter from '../../../hooks/useOutsideAlerter';

interface MultiselectOption {
    label: string;
    value: string;
}

interface INextUpFilterProps {
    filterValues: string[];
    setFilterValues: (newValues: string[]) => void;
}

const NextUpFilter = ({ filterValues, setFilterValues }: INextUpFilterProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const selectRef = useRef<any>(null);

    useOutsideAlerter(containerRef, () => {
        selectRef.current?.props?.onMenuClose();
    });

    const onChangeFilter = (newValues: MultiValue<{ label: string; value: string; }>) => {
        const selections = newValues.map((v: MultiselectOption) => v.value);
        setFilterValues(selections);
        localStorage.setItem('animalTrackerFilters', JSON.stringify(selections));
    };

    useEffect(() => {
        // Attempt to restore filters from local storage
        const stored = localStorage.getItem('animalTrackerFilters');
        if (stored !== null) {
            setFilterValues(JSON.parse(stored));
        } else { // Otherwise select all by default
            setFilterValues(getFilterOptions());
        }
    }, [setFilterValues]);

    const getFilterOptions = () => {
        const sorted = getRareAnimals().sort((a: IAnimal, b: IAnimal) => a.name > b.name ? 1 : -1);
        return sorted.map((a: IAnimal) => a.name);
    };

    const onClickValueContainer = () => {
        selectRef.current?.props?.onMenuOpen();
    };

    return (
        <FormControl mb={4} ref={containerRef}>
            <Select
                ref={selectRef}
                isMulti
                name="animalsToTrack"
                options={getFilterOptions().map((o: string) => ({ label: o, value: o }))}
                value={filterValues.map((o: string) => ({ label: o, value: o }))}
                selectedOptionStyle="check"
                hideSelectedOptions={false}
                placeholder="Select animals to track"
                closeMenuOnSelect={false}
                onChange={onChangeFilter}
                components={{
                    ValueContainer: ({ children, ...props }) => {
                        const { getValue } = props;

                        return (
                            <selectComponents.ValueContainer {...props}>
                                <Flex onClick={onClickValueContainer} style={{ width: '100%' } as CSSProperties}>{getValue().length ? `Showing ${getValue().length} of ${getFilterOptions().length} rare animals` : 'Select animals to track'}</Flex>
                            </selectComponents.ValueContainer>
                        );
                    },
                    Placeholder: ({ children, ...props }) => {
                        return (
                            <selectComponents.Placeholder {...props}>
                                {children}
                            </selectComponents.Placeholder>
                        );
                    },
                    MultiValueContainer: () => {
                        return null;
                    },
                    Input: () => {
                        return null;
                    },
                }}
            />
        </FormControl>
    );
};

export default memo(NextUpFilter);